import React, {Component} from 'react';

class ScrollDownComponent extends Component {
    render() {
        return (
            <div className="scroll-down-item">
                <span></span>
            </div>
        );
    }
}

export default ScrollDownComponent;