import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../constants/routePaths";

class ReturnButtonComponent extends Component {
    render() {
        let {tags,activeLang} = this.props;
        return (
            <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${(tags && tags[0]) ? tags[0].tag.tag_link:''}`} className="return-btn-item">
                <i className="icon-crossed-arrow"></i>
                <p>{(tags && tags[0]) && tags[0].tag.tag_name}</p>
            </Link>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    tags:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].tags:null,
    activeLang: state.langReducer.activeLang
})

export default connect(mapStateToProps)(ReturnButtonComponent);