import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
class CorporateYearContainer extends Component{
    render() {
        let {year,desc,img1x,img2x,img3x} = this.props;
        return (
            <div className="img-block">
                <div className="year-item">
                    <span>{year}</span>
                </div>
                <figure className="img-item">
                    <LazyLoad placeholder={<IMG/>}>
                    <picture>
                        <source
                            srcSet={`${ASSETS}${img1x} 1x, ${ASSETS}${img2x} 2x, ${ASSETS}${img3x} 3x`}
                            type="image/jpeg"/>
                        <source
                            srcSet={`${ASSETS}${img1x.substring(0, img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${img2x.substring(0, img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${img2x.substring(0, img3x.lastIndexOf("."))}.webp 3x`}
                            type="image/webp"/>
                        <img src={`${ASSETS}${img1x} 1x`}
                             alt="article-list"/>
                    </picture>
                    </LazyLoad>
                </figure>
                <div className="description-item">
                    <span>{year}</span>
                    <p>{desc}</p>
                </div>
            </div>
        );
    }
}

export default CorporateYearContainer;