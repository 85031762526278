import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {CORPORATE_WITHOUT_LINK, SLASH_ROUTE} from "../../constants/routePaths";
import {isScrolledIntoView} from "../../helpers";

class CorporateSubLinkContainer extends Component{
    render() {
        let {links,activeLang,match} = this.props;
        return(
            <div className="company-list-item">
                <ul>
                    {
                        links.map((linkItem,key)=>
                            <li key={key} className={"first-list-item "+(match.params.subLink===linkItem.link?"active":'')}>
                                <NavLink to={`${SLASH_ROUTE}${activeLang}${CORPORATE_WITHOUT_LINK}/${linkItem.link}`}>{linkItem.title}</NavLink>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    links: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].links:[],
    activeLang: state.langReducer.activeLang
});

export default connect(mapStateToProps)(CorporateSubLinkContainer);