import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
class InformationSocietyServices extends Component{
    render() {
        let {society_services} = this.props;
        return(
            <div className="company-profile-block" id={"contents-5"}>
                {
                    society_services &&
                    <div className="img-block">
                        <figure className="img-item">
                            <LazyLoad placeholder={<IMG/>}>
                            <picture>
                                <source
                                    srcSet={`${ASSETS}${society_services.img1x} 1x, ${ASSETS}${society_services.img2x} 2x, ${ASSETS}${society_services.img3x} 3x`}
                                    type="image/jpeg"/>
                                <source
                                    srcSet={`${ASSETS}${society_services.img1x.substring(0, society_services.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${society_services.img2x.substring(0, society_services.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${society_services.img3x.substring(0, society_services.img3x.lastIndexOf("."))}.webp 3x`}
                                    type="image/webp"/>
                                <img src={`${ASSETS}${society_services.img1x} 1x`}
                                     alt="article-list"/>
                            </picture>
                            </LazyLoad>
                        </figure>
                    </div>
                }
                {
                    society_services &&
                    <div className="description-item ">
                        <h2>{society_services.title}</h2>
                        {
                            society_services.infos && society_services.infos.map((infoItem,key)=>
                                <div key={key} className="corporate-info-item">
                                    <h3>{infoItem.title}</h3>
                                    {
                                        infoItem.items && infoItem.items.map((item,subKey)=>
                                            <p key={key+'-'+subKey}><span>{item.key}: </span>{item.value}</p>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                }

            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    society_services: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].society_services:null
});

export default connect(mapStateToProps)(InformationSocietyServices);