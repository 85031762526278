import React, {Component, Fragment} from 'react';
import PageService from "../../pageServices/pageService";
import {connect} from "react-redux";
import {setDocumentTitle} from "../../helpers/index";
import NotFoundPage from './../notFoundPage';
class KvkkPage extends Component {
    
    componentDidMount() {
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch);
        if(this.props.kvkkData && this.props.kvkkData.metas && this.props.kvkkData.metas.title){
            setDocumentTitle(this.props.kvkkData.metas.title);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.kvkkData && nextProps.kvkkData.metas && (nextProps.kvkkData!== this.props.kvkkData)){
            if(nextProps.kvkkData.metas && nextProps.kvkkData.metas.title){
                setDocumentTitle(nextProps.kvkkData.metas.title);
            }
        }

    }
    render() {
        let {kvkkData,activeLang} = this.props;
        
        return (
            <div>
                {
                    activeLang === "tr" ?
                    <div className="privacy-root kvkk">
                        {
                            kvkkData &&
                            <div className="content-root safe-area">
                                <div className="content-block">
                                    <div className="title-item">
                                       <h1>{kvkkData.title}</h1>
                                    </div>
                                    {
                                        kvkkData.descArea.map((data,index)=>
                                            <div className='text-item' key={index} dangerouslySetInnerHTML={{__html:data}}></div>
                                        )
                                    }
                                </div>
                            </div>
                           
                        }
                    </div>
                    :
                    <NotFoundPage {...this.props}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    kvkkData: state.productivityReducer[state.langReducer.activeLang],
    activeLang: state.langReducer.activeLang
})

export default connect(mapStateToProps)(KvkkPage);
