import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import LazyLoad from "react-lazyload";
import IMG from "../../components/common/lazyComponent";
class ArticleGalleryContainer extends Component{
    render() {
        let imgArr = JSON.parse(this.props.content_text)
        return(
            <div className="image-block">
                <div className="image-items">
                    {
                        imgArr.map((imgItem,key)=>
                            <figure key={key} className="image-item">
                                <LazyLoad placeholder={<IMG/>}>
                                <picture>
                                    {/*<source srcSet={`${ASSETS}/img/article-detail/article-middle-image@1x.webp 1x, ${ASSETS}/img/article-detail/article-middle-image@2x.webp 2x, ${ASSETS}/img/article-detail/article-middle-image@3x.webp 3x`}*/}
                                    {/*        type="image/webp"/>*/}
                                    {/*<source srcSet={`${ASSETS}/img/article-detail/article-middle-image@1x.jpg 1x, ${ASSETS}/img/article-detail/article-middle-image@2x.jpg 2x, ${ASSETS}/img/article-detail/article-middle-image@3x.jpg 3x `}*/}
                                    {/*        type="image/jpeg"/>*/}
                                    <img src={`${ASSETS}${imgItem.path}`} alt="article"/>
                                </picture>
                            </LazyLoad>
                            </figure>
                        )
                    }
                </div>
                <div className="image-text-item">
                    {
                        this.props.gallery_description&&
                        <p>{this.props.gallery_description}</p>
                    }

                </div>
            </div>
        )
    }
}

export default ArticleGalleryContainer;
