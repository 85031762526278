import React,{Component} from 'react';

class CircleComponent extends Component{
    strokeDashOffset = 0;
    interval;
    componentDidMount() {
        this.createInterval()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.circleKey!==this.props.circleKey){
            this.createInterval();
        }
    }

    createInterval = () => {

        this.strokeDashOffset = 0;
        this.forceUpdate();
        clearInterval(this.interval)
        this.interval = setInterval(()=>{
            if(this.strokeDashOffset<289){
                this.strokeDashOffset+=1
                this.forceUpdate();
            }
        },10000/289)

    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return(
            <div className="countdown-timer" >
                <svg width="96" height="96" xmlns="http://www.w3.org/2000/svg">
                    <path d="m 48,2
          a 46,46 0 1,0 0,92
          a 46,46 0 1,0 0,-92" fill="none" stroke="rgba(255, 255, 255, .25)" strokeWidth="4"></path>
                    <path d="m 48,2
          a 46,46 0 1,0 0,92
          a 46,46 0 1,0 0,-92" fill="none" stroke="rgba(255, 255, 255, 1)" strokeLinecap="round" strokeWidth="4" strokeDasharray="289" strokeDashoffset={this.strokeDashOffset}></path>
                </svg>
                <div aria-hidden="true" className="count-text-item" >
                  <span>{this.props.text}</span>
                </div>
            </div>
        )
    }
};

export default CircleComponent
