import React,{Component} from 'react';
import {Field} from "redux-form";
import InputFieldComponent from "./inputFieldComponent";

class RadioFieldComponent extends Component{
    render() {
        let {input,options} = this.props;
        return(
            <div className="radio-block">
                {
                    options.map((optionItem,key)=>
                        <div key={key} className={"radio-item "+(optionItem.value===input.value?"active":"")} onClick={()=>{
                            input.onChange(optionItem.value)
                        }}>
                            <span></span>
                            <input onChange={()=>{}} checked={optionItem.value==input.value} autoComplete="off" type="radio" name={input.name} />
                            <label htmlFor="">{optionItem.name}</label>
                        </div>
                    )
                }
            </div>
        )
    }
};

export default RadioFieldComponent;
