import React, {Component} from 'react';
import {connect} from "react-redux";

class ArticleShortDescContainer extends Component {
    render() {
        let {description} = this.props;
        return (
                <div className="explanation-block">
                    <div className="safe-area explanation-item">
                        <div className="text-item">
                            {
                                description &&
                                <p>{description}</p>
                            }
                        </div>
                    </div>
                </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => ({
    description: state.articleDetailReducer[ownProps.articleLink] ? state.articleDetailReducer[ownProps.articleLink].description : null
})

export default connect(mapStateToProps)(ArticleShortDescContainer);