import async from "async";
import * as WebService from '../services/webService';
import * as actionTypes from "../constants/actionTypes";

const articleListData = (data) => ({
    type: actionTypes.GET_ARTICLE_LIST_DATA,
    payload: data
});

export const getArticleListDataInit = (dispatch,params) => {
    return new Promise((resolve,failure)=>{
        async.series([
            (cb) => {
                WebService.getArticleListData(params)
                    .then((res) => {
                        if (res.data.success) {
                            cb(null,res.data.success);
                        }else{
                            cb(null,[]);
                        }
                    })
                    .catch((err) => {
                        cb(null,[]);
                    })
            }
        ],(err,result)=>{
            dispatch(articleListData({[params.tag]:result[0]}));
            resolve(result);
        });
    });


};