import React, {Component, Fragment} from 'react'

class HoverListComponent extends Component{
    ulList = React.createRef();
    parentWidth=0;

    componentDidMount() {
        this.parentWidth = this.ulList.current.parentNode.offsetWidth || this.ulList.current.parentNode.parentNode.offsetWidth;
        this.forceUpdate()
    }

    render() {
        let { textValue } = this.props;
        let count = Math.floor((this.parentWidth * Math.PI)/(textValue.length*13));
        let arr = new Array(count);
        arr.fill("");
        return(
            <ul ref={this.ulList}>
                {
                    arr.map((item,key)=>
                        <Fragment key={key}>
                            {key !== 0 && <li></li>}
                            {textValue.split("").map((charItem,subKey) =>
                                <li key={subKey} style={{transform: `translate(-50%, -50%) rotate(${((key*textValue.length)+subKey) * 360/(arr.length*textValue.length) - 360/(arr.length*textValue.length)}deg) translateY(-40px) rotateX(0)`}}>{charItem}</li>
                            )}
                        </Fragment>
                    )
                }
            </ul>
        )
    }
};

export default HoverListComponent;