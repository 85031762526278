import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {SLASH_ROUTE} from "../../../constants/routePaths";

class ContactComponent extends Component {
    render() {
        let {activeLang,footerData} = this.props;
        return (
            <div className="get-in-touch-item">
                {
                    footerData &&
                    <ul>
                        <li className="contact-item"><a href="tel:+902166456900" rel="noreferrer" title="telephone">+90 216 645 69 00</a></li>
                        <li className="contact-item"><a href="https://www.google.com/maps/place/%C3%9Cnl%C3%BC+Tekstil+San.Tic.+A.%C5%9E./@40.9945259,29.1935792,17z/data=!3m1!4b1!4m5!3m4!1s0x14cacf61c73e4aa1:0x2ed18702ea7563f!8m2!3d40.9945229!4d29.1952189" target="_blank" rel="noreferrer" >{footerData.direction}</a></li>
                        <li className="contact-item"><Link to={`${SLASH_ROUTE}${activeLang}/${footerData.get_in_touch_link}`}  >{footerData.get_in_touch}</Link></li>
                    </ul>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
    footerData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].footer:null
});

export default connect(mapStateToProps)(ContactComponent);