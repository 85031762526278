import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import IMG from "../../common/lazyComponent";
import LazyLoad from "react-lazyload";
import {Link, NavLink} from "react-router-dom";
class BannerBigSlideItem extends Component {
    render() {
        const {imgWeb, mobilWeb1x, mobilWeb2x, mobilWeb3x, img, mobilJpg1x, mobilJpg2x, mobilJpg3x, alt, link,} = this.props;
        return (
            <Link to={`${link}`} className="slide-image">
                {/*<LazyLoad placeholder={<IMG/>}>*/}
                <picture>
                    <source media="(min-width: 421px)"
                            srcSet={`${ASSETS}${imgWeb} 1x`}
                            type="image/webp"/>
                    <source media="(min-width: 421px)"
                            srcSet={`${ASSETS}${img} 1x`}
                            type="image/jpeg"/>

                    <source media="(max-width: 420px)"
                            srcSet={`${ASSETS}${mobilWeb1x} 1x, ${ASSETS}${mobilWeb2x} 2x, ${ASSETS}${mobilWeb3x} 3x`}
                            type="image/webp"/>
                    <source media="(max-width: 420px)"
                            srcSet={`${ASSETS}${mobilJpg1x} 1x, ${ASSETS}${mobilJpg2x} 2x, ${ASSETS}${mobilJpg3x} 3x`}
                            type="image/jpeg"/>
                    <img src={`${ASSETS}${img}`} alt={alt}/>
                </picture>
                {/*</LazyLoad>*/}
            </Link>
        );
    }
}

export default BannerBigSlideItem;
