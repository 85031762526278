import xss from "xss";

export const required = (value) =>
  value && value.trim() !== "" ? undefined : "Required";

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const checkPhoneNumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d+]/g, "");

  return `${onlyNums}`;
};

export const getElementWidth = (element) => {
  if (!element) {
    console.error("Element is undefined or null.");
    return 0;
  }

  var style = window.getComputedStyle(element), // getComputedStyle kullanıyoruz
    width = element.offsetWidth, // style.width yerine offsetWidth kullanıyoruz
    margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);

  return width + margin;
};

export const getElementPosition = (el) => {
  var xPos = 0;
  var yPos = 0;
  while (el) {
    if (el.tagName === "BODY") {
      xPos += el.offsetLeft + el.clientLeft;
      yPos += el.offsetTop + el.clientTop;
    } else {
      // for all other non-BODY elements
      xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPos += el.offsetTop - el.scrollTop + el.clientTop;
    }

    el = el.offsetParent;
  }
  return {
    x: xPos,
    y: yPos,
  };
};

export const checkElementInViewPort = (element) => {
  if (
    getElementPosition(element).y <
      window.scrollY + (window.innerHeight * 2) / 3 &&
    !element.className.includes("scrollanim")
  ) {
    element.classList.add("scrollanim");
  }
};

export const xssWhiteList = {
  whiteList: {
    ...xss.whiteList,
    a: ["href", "target"],
    iframe: ["src", "class", "allowfullscreen"],
    div: ["class", "style"],
    p: ["class", "style"],
    img: ["src", "data-src", "class", "alt", "srcset", "sizes"],
    blockquote: ["class", "style"],
    time: ["style", "datetime"],
  },
  onTagAttr: function (tag, name, value, isWhiteAttr) {
    if (tag == "img") {
      if (name == "src") {
        let lastValue =
          value && value[0] !== "/" && value.indexOf("http") === -1
            ? "/" + value
            : value;
        return `${name}="${lastValue}"`;
      }
    }
  },
};

export const isScrolledIntoView = (elem) => {
  let docViewTop = window.scrollY || window.pageYOffset;
  let docViewBottom = docViewTop + window.innerHeight;
  let elemTop = elem.offsetTop;
  let elemOffSetHeight =
    elem.offsetHeight > window.innerHeight
      ? window.innerHeight - 70
      : elem.offsetHeight;
  let elemBottom = elemTop + elemOffSetHeight;
  return elemBottom <= docViewBottom && elemTop >= docViewTop;
};

export const setDocumentTitle = (titleValue) => {
  if (typeof window !== "undefined") {
    document.title = titleValue;
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical !== null) {
      if (window.location.href.indexOf("?sa") > -1) {
        //canonical.forEach(e => e.parentNode.removeChild(e));
        canonical.remove();
      } else {
        canonical.href = window.location.href;
      }
    }
  }
};

export const removeCursorClass = (className = "white-dot") => {
  if (typeof window !== "undefined") {
    let cursorItem = document.getElementById("cursorLazy");
    cursorItem.classList.remove(className);
  }
};

export const addCursorClass = (className = "white-dot") => {
  if (typeof window !== "undefined") {
    let cursorItem = document.getElementById("cursorLazy");
    cursorItem.classList.add(className);
  }
};

export const removeCursorConClass = (className = "hide-active") => {
  if (typeof window !== "undefined") {
    let cursorItem = document.getElementById("cursorCon");
    cursorItem.classList.remove(className);
  }
};

export const addCursorConClass = (className = "hide-active") => {
  if (typeof window !== "undefined") {
    let cursorItem = document.getElementById("cursorCon");
    cursorItem.classList.add(className);
  }
};
