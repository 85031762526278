import {GET_CORPORATE_DATA} from "../constants/actionTypes";

const initialState = {};
const corporateReducer = (state =initialState,action) =>{
    switch (action.type) {
        case GET_CORPORATE_DATA:
            return {
                ...state,
                pageData:action.payload
            };
        default:
            return state
    }
};

export default corporateReducer;
