import React, {Component} from 'react';
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import SearchComponent from "../../../components/search/searchComponent";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../../constants/routePaths";
import {getElementPosition, getElementWidth} from "../../../helpers";
import {setDocumentTitle} from "../../../helpers/index";

class ArticleListSlideComponent extends Component {
    articleTag = React.createRef();
    state = {
        title:"Ünlü Article List"
    };


    getArticleTagLi = () => {
        let articleTagLi = this.articleTag.current;
        let _toplam = 0;
        let _containerWidth = 0;
        Array.from(articleTagLi.children).map((item) => {
            _containerWidth += getElementWidth(item);
        })
        let offsetValue = getElementPosition(this.articleTag.current).x
        _containerWidth += offsetValue;
        Array.from(articleTagLi.children).map(_li => {
            if (_li.classList.contains("active")) {
                articleTagLi.style.transform = this.articleTag.current.offsetWidth + _toplam < (_containerWidth - offsetValue) ? `translateX(-${_toplam}px)` : `translateX(-${_containerWidth - window.innerWidth + offsetValue}px)`;
                articleTagLi.style.transition = 'transform 1s cubic-bezier(0.64, 0.01, 0.36, 1)';
            }
            _toplam += getElementWidth(_li);

        })
    }

    componentDidMount() {
        let {articleTagsData} = this.props;
        let elem = articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link) && articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link).tag_name?articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link).tag_name:null;
        if(elem){
            setDocumentTitle(elem + " | Ünlü Tekstil");
        }

    }

    componentDidUpdate(prevProps) {
        let _prevPath = prevProps.match.url;
        let _path = this.props.match.url;
        if ((_prevPath !== _path) || (prevProps.articleTagsData !== this.props.articleTagsData)) {
            //TODO: Timeoutsuz bakilacak.
            let {articleTagsData} = this.props;
            let elem = articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link)&&articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link).tag_name?articleTagsData.find(item=>this.props.match.params.tag_link === item.tag_link).tag_name:null;
            if(elem){
                setDocumentTitle(elem + " | Ünlü Tekstil");
            }
            this.setState({title:elem + " | Ünlü Tekstil"});
            setTimeout(() => {
                this.getArticleTagLi();
            }, 100)
        }

    }

    changeActiveWithDirection = (direction) => {
        let {activeLang, match,articleTagsData} = this.props;
        let activeElementIndex = articleTagsData.findIndex(item=>this.props.match.params.tag_link === item.tag_link);
        if(activeElementIndex+direction>-1 && activeElementIndex+direction<articleTagsData.length){
            this.props.history.push(`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${articleTagsData[activeElementIndex+direction].tag_link}`)
        }
    }

    render() {
        let {activeLang, match,articleTagsData} = this.props;
        const articleTags = this.props.articleTagsData;
        const totalCount = this.props.totalCountData;
        let activeElementIndex = articleTagsData.findIndex(item=>this.props.match.params.tag_link === item.tag_link);
        return (
            <div className="article-list-slide-root">
                <h1 style={{position:"absolute",visibility: "hidden",opacity:0}}>{this.state.title}</h1>
                <div className="article-list-slide-block">
                    <div className="safe-area article-slide-content">
                        <div className="mobile-slide-list">

                            <div className="select-block">
                                <select onChange={(e) => {
                                    this.props.history.push(`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${e.target.value}`)
                                }} value={match.params.tag_link}>
                                    {articleTags && articleTags.map((tag, index) => {
                                        return (
                                            <option key={index} value={tag.tag_link}
                                                    className={`slide-item ${match.params.tag_link === tag.tag_link ? "active" : ""}`}>
                                                {tag.tag_name}
                                            </option>
                                        )
                                    })}
                                </select>
                                <span className="gg-select"></span>
                            </div>

                        </div>
                        <div className="grd-item"></div>

                        <div className="slider-block">
                            <div onClick={()=>{
                                this.changeActiveWithDirection(-1)
                            }} className={"arr-item left-item "+(activeElementIndex===0?"disabled":'')}>
                                <i className="gg-arrow left-arr"></i>
                            </div>
                            <ul className="slide-list" ref={this.articleTag}>
                                {articleTags && articleTags.map((tag, index) => {
                                    return (
                                        <li key={index}
                                            className={`slide-item ${match.params.tag_link === tag.tag_link ? "active" : ""}`}>
                                            <NavLink
                                                to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${tag.tag_link}`}>{tag.tag_name}</NavLink>
                                            <span>{totalCount}</span>
                                        </li>
                                    )
                                })}

                            </ul>
                            {
                                articleTags &&
                                <div className={"arr-item "+(activeElementIndex===articleTags.length-1?"disabled":'')} onClick={()=>{
                                    this.changeActiveWithDirection(1)
                                }}>
                                    <i className="gg-arrow"></i>
                                </div>
                            }

                        </div>
                        <div className="grd-item right-grd"></div>
                    </div>
                </div>
                <div className="safe-area">
                    <SearchComponent tag_link={this.props.tag_link} activeLang={this.props.activeLang}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    articleTagsData: state.articleListTagsReducer.articleListTags ? state.articleListTagsReducer.articleListTags : [],
    totalCountData: state.articleListReducer.articleList && state.articleListReducer.articleList[ownProps.tag_link] ? state.articleListReducer.articleList[ownProps.tag_link].contentArticle.totalCount : null,

    activeLang: state.langReducer.activeLang
});

export default connect(mapStateToProps)(ArticleListSlideComponent);
