import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import {NavLink} from "react-router-dom";
import ArrowCircleLoopComponent from "../../common/arrowCircleLoopComponent";

class ReviewComponent extends Component {
    render() {
        const {head, year, img1xWebp, img1x, alt, classname,view} = this.props;
        return (
            <NavLink to="#" className={`review-item ${classname ? classname : ''}`}>
                <div className="top-item">
                    <div className="title-item">
                        <h3>{head}</h3>
                        <span>{year}</span>
                    </div>
                 <ArrowCircleLoopComponent text="open"/>
                </div>
                <div className="image-item">
                    <picture>
                        <source
                                srcSet={`${ASSETS}${img1xWebp} 1x`}
                                type="image/webp"/>
                        <source
                                srcSet={`${ASSETS}${img1x} 1x`}
                                type="image/jpeg"/>
                        <img src={`${ASSETS}${img1x}`} alt={alt}/>
                    </picture>
                </div>
                <div className="regular-btn">
                    <i className="icon-crossed-arrow-small"></i>
                    <span>{view}</span>
                </div>
            </NavLink>
        );
    }
}

export default ReviewComponent;
