import React, {Component, Fragment} from 'react';
import PageService from "../../pageServices/pageService";
import {connect} from "react-redux";
import {setDocumentTitle} from "../../helpers/index";
import NotFoundPage from './../notFoundPage';
class ProductivityPage extends Component {
    
    componentDidMount() {
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch);
        if(this.props.productivityData && this.props.productivityData.metas && this.props.productivityData.metas.title){
            setDocumentTitle(this.props.productivityData.metas.title);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.productivityData && nextProps.productivityData.metas && (nextProps.productivityData!== this.props.productivityData)){
            if(nextProps.productivityData.metas && nextProps.productivityData.metas.title){
                setDocumentTitle(nextProps.productivityData.metas.title);
            }
        }

    }
    render() {
        let {productivityData,activeLang} = this.props;
       
        return (
            <div>
                {
                    activeLang === "tr" ?
                    <div className="privacy-root productivity">
                        {
                            productivityData &&
                            <div className="content-root safe-area">
                                <div className="content-block">
                                    <h1>{productivityData.title}</h1>
                                {
                                    productivityData.listDescArea.map((item,index)=>
                                       <div className="text-item" key={index} dangerouslySetInnerHTML={{__html:item}}/>
                                    )
                                }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <NotFoundPage {...this.props}/>
                }
                
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    productivityData: state.productivityReducer[state.langReducer.activeLang],
    activeLang: state.langReducer.activeLang
})

export default connect(mapStateToProps)(ProductivityPage);
