import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import PageService from "../../pageServices/pageService";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
import {setDocumentTitle} from "../../helpers/index";
class PrivacyPolicy extends Component {
    componentDidMount() {
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch);
        if(this.props.privacyData && this.props.privacyData.metas && this.props.privacyData.metas.title){
            setDocumentTitle(this.props.privacyData.metas.title);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.privacyData&& this.props.privacyData && (nextProps.privacyData.metas !== this.props.privacyData.metas)){
            if(nextProps.privacyData.metas && nextProps.privacyData.metas.title){
                setDocumentTitle(nextProps.privacyData.metas.title);
            }
        }

    }
    render() {
        let {privacyData} = this.props;
        return (
            <div>
                <div className="privacy-root">
                    <div className="sub-banner-root safe-area">
                        <div className="sub-banner-block">
                            {
                                privacyData &&
                                <figure className="img-item">
                                    {/*<LazyLoad placeholder={<IMG/>}>*/}
                                    <picture>
                                        <source
                                            srcSet={`${ASSETS}${privacyData.img1X} 1x, ${ASSETS}${privacyData.img2X} 2x, ${ASSETS}${privacyData.img3X} 3x`}
                                            type="image/jpeg"/>
                                        <source
                                            srcSet={`${ASSETS}${privacyData.img1X.substring(0, privacyData.img1X.lastIndexOf("."))}.webp 1x, ${ASSETS}${privacyData.img2X.substring(0, privacyData.img2X.lastIndexOf("."))}.webp 2x, ${ASSETS}${privacyData.img3X.substring(0, privacyData.img3X.lastIndexOf("."))}.webp 3x`}
                                            type="image/webp"/>
                                        <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg`}
                                             alt="article-list"/>
                                    </picture>
                                    {/*</LazyLoad>*/}
                                </figure>
                            }

                        </div>
                    </div>
                    {
                        privacyData &&
                        <div className="content-root safe-area">
                            <div className="content-block">
                                <h1>{privacyData.title}</h1>
                                {privacyData.descriptions.map((descItem,key)=>
                                    <p key={key}>{descItem}</p>
                                ) }
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    privacyData: state.privacyReducer[state.langReducer.activeLang]
})

export default connect(mapStateToProps)(PrivacyPolicy);