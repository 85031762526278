import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import IMG from "../../common/lazyComponent";
import LazyLoad from "react-lazyload";
class BannerSmallSliderItem extends Component {
    render() {
        const {imgWeb, img, alt} = this.props;
        return (
            <div className="slide-small-image">
                {/*<LazyLoad placeholder={<IMG/>}>*/}
                <picture>
                    <source
                            srcSet={`${ASSETS}${imgWeb} 1x`}
                            type="image/webp"/>
                    <source
                            srcSet={`${ASSETS}${img} 1x`}
                            type="image/jpeg"/>
                    <img src={`${ASSETS}${img}`} alt={alt}/>
                </picture>
                {/*</LazyLoad>*/}
            </div>
        );
    }
}

export default BannerSmallSliderItem;