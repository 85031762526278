import React, {Component} from 'react';
import {connect} from "react-redux";

class CorporateQualityContainer extends Component{
    render() {
        let {qualityData} = this.props;
        return(
            <article className="main-text-block">
                {
                    qualityData &&
                    <div className="text-item">
                        <h1>{qualityData.title}</h1>
                        <p>{qualityData.desc}</p>
                    </div>
                }
            </article>
        )
    }
};

const mapStateToProps = (state) => ({
    qualityData: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].quality:null

});

export default connect(mapStateToProps)(CorporateQualityContainer);