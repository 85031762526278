import React,{Component} from 'react';
import {Link} from "react-router-dom";
import SliderComponent from "../common/sliderComponent";
import ContentImgItemComponent from "../home/content/contentImgItemComponent";
import {connect} from "react-redux";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../constants/routePaths";

class SuggestComponent extends Component{
    render() {
        let {suggests,read_more,discover_all,activeLang,tags} =this.props;
        return(
            <div className={"content-slider-block multiple scrollanim"}>

                {
                    (suggests && suggests.length > 0) &&
                    <div className="head-block" ref={this.containerRef}>
                        <h2>{read_more}</h2>
                        <div className="link-item">
                            <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${(tags && tags[0]) ? tags[0].tag.tag_link:''}`}>
                                <div className="line"></div>
                                <span>{discover_all}</span>
                            </Link>
                        </div>

                    </div>
                }

                {
                    (suggests && suggests.length > 0) &&
                    <SliderComponent>
                        {
                            suggests.map((item, key) =>
                                <ContentImgItemComponent activeLang={activeLang} key={key} {...item}/>
                            )
                        }
                    </SliderComponent>
                }
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    tags:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].tags:null,
    activeLang: state.langReducer.activeLang,
    suggests:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].suggestArticle:[],
    read_more:state.layoutReducer[ownProps.activeLang]?state.layoutReducer[ownProps.activeLang].common.read_more:null,
    discover_all:state.layoutReducer[ownProps.activeLang]?state.layoutReducer[ownProps.activeLang].common.discover_all:null,
})

export default connect(mapStateToProps)(SuggestComponent);