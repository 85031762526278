import React, {Component} from 'react';
import {connect} from "react-redux";

class ConversationComponent extends Component {
    render() {
        let {contactData} = this.props;
        return (
           <div className="conversation-text-block">
               {
                   contactData &&
                   <div className="conversation-item">
                       <h3>{contactData.conversation.title}</h3>
                       {
                           contactData.conversation.descs.map((descItem,key)=>
                               <p key={key}>{descItem}</p>
                           )
                       }
                   </div>
               }
           </div>
        );
    }
}
const mapStateToProps = (state) => ({
    contactData: state.contactReducer[state.langReducer.activeLang] ? state.contactReducer[state.langReducer.activeLang].contactData : null
});


export default connect (mapStateToProps)(ConversationComponent);