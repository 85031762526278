import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {ARTICLE_DETAIL_WITHOUT_PARAM, HOME, SLASH_ROUTE} from "../../constants/routePaths";
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {changeLangAction} from "../../actions/langAction";
import HeaderTagsComponent from "./headerTagsComponent";
import RequestCatalog from "../requestCatalog/requestCatalog";

class HeaderComponent extends Component {
    state = {isSticky:false,openMore:false,isRequested:false};
    headerRoot = React.createRef();
    mobileOpenMenu = React.createRef();
    noScroll = React.createRef();
    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", this.addStickyToHeader);
            window.addEventListener("scroll", this.respMenuAnimation, {passive: true});
            window.addEventListener('resize',this.resizingWindow);
        }

    }
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("scroll", this.addStickyToHeader);
            window.removeEventListener("scroll", this.respMenuAnimation);
            window.removeEventListener('resize',this.resizingWindow);
        }
    }
    addStickyToHeader = () => {
        if (typeof window !== "undefined") {
            if (window.pageYOffset > 0 && this.state.isSticky === false) {
                this.headerRoot.current.classList.add("sticky-header");
                this.setState({isSticky: true});
            } else if (window.pageYOffset === 0 && this.state.isSticky === true) {
                this.headerRoot.current.classList.remove("sticky-header");
                this.setState({isSticky: false});
            }
        }
    };
    scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.mobileOpenMenu.current && prevProps.location.pathname !== this.props.location.pathname){
            this.mobileOpenMenu.current.classList.remove("opened");
           this.removeNoScroll()
        }
    }
    removeNoScroll = ()=>{
        document.body.classList.remove('no-scroll')
    }
    addNoScroll = ()=>{
        document.body.classList.add('no-scroll')
    }
    changeLang = () => {
        let currentLang =this.props.activeLang;
        let newLang =this.props.activeLang==="tr"?'en':'tr';
        this.props.dispatch(changeLangAction(newLang));
        this.props.history.push(this.props.isOtherLangExits ? this.props.location.pathname.replace(currentLang,newLang):`${SLASH_ROUTE}${newLang}`)
    }

    changeRequestValue = (value) => {

        if(value === true){
          this.addNoScroll()

        }else if(typeof window !== "undefined" && window.innerWidth>990){
            this.removeNoScroll()
        }
        this.setState({
            isRequested:value
        })

    }

    resizingWindow = () => {
        if(window.innerWidth > 480){
            this.removeNoScroll()
        }
    }
    toggleMenu = (e) => {
        if(typeof window !== "undefined" && window.innerWidth < 990  ){
            this.mobileOpenMenu.current.classList.toggle("opened");
            this.noScroll.current.classList.toggle("mobile");
            if (  e.currentTarget.className.indexOf("mobile") === -1) {
                this.removeNoScroll()
            }else{
                this.addNoScroll()
            }

        }
        else if (typeof window !== "undefined" && window.innerWidth > 990){
            this.removeNoScroll()
        }

    }

    ;

    respMenuAnimation = () => {
        if (window.innerWidth < 729 && window.pageYOffset > 100 && this.headerRoot.current) {
            let newValue = window.pageYOffset;

            //Subtract the two and conclude
            if (this.oldValue - newValue < 0) {
                this.headerRoot.current.classList.add("hide");
            } else if (this.oldValue - newValue > 0) {
                this.headerRoot.current.classList.remove("hide");
            }
            // Update the old value
            this.oldValue = newValue;

        }
        if (this.headerRoot.current && window.innerWidth < 729 && document.documentElement && document.documentElement.offsetHeight < window.pageYOffset + window.innerHeight + 70) {
            this.headerRoot.current.classList.remove("hide");
        }
    };
    render() {
        const {menuItems,activeLang,headerData,isOtherLangExits} = this.props;
        const {isSticky,isRequested} = this.state;
        return (
            <div className={`header-root ${isSticky === true ? "sticky-header" : ""} ${this.props.location.pathname===HOME.replace(":lang",activeLang) ? "home": ""}`} ref={this.headerRoot} id="header">
                <div className="header-container">
                    <div className="header-block" ref={this.mobileOpenMenu}>
                        <NavLink to={`${SLASH_ROUTE}${activeLang}`} className="logo-item" onClick={()=>{
                            this.scrollToTop();
                        }} >
                            <picture>
                                <source media="(min-width: 991px)"
                                        srcSet={`${ASSETS}/img/banner-logo/banner-logo@1x.png 1x, ${ASSETS}/img/banner-logo/banner-logo@2x.png 2x, ${ASSETS}/img/banner-logo/banner-logo@3x.png 3x`}
                                        type="image/png"/>

                                <source media="(min-width: 991px)"
                                        srcSet={`${ASSETS}/img/banner-logo/banner-logo@1x.webp 1x, ${ASSETS}/img/banner-logo/banner-logo@2x.webp 2x, ${ASSETS}/img/banner-logo/banner-logo@3x.webp 3x`}
                                        type="image/webp"/>

                                <source media="(max-width:990px)"
                                        srcSet={`${ASSETS}/img/mobil-header-logo/mobil-header-logo@1x.webp 1x, ${ASSETS}/img/mobil-header-logo/mobil-header-logo@2x.webp 2x, ${ASSETS}/img/mobil-header-logo/mobil-header-logo@3x.webp 3x`}
                                        type="image/webp"/>

                                <source media="(max-width:990px)"
                                        srcSet={`${ASSETS}/img/mobil-header-logo/mobil-header-logo@1x.jpg 1x, ${ASSETS}/img/mobil-header-logo/mobil-header-logo@2x.jpg 2x, ${ASSETS}/img/mobil-header-logo/mobil-header-logo@3x.jpg 3x`}
                                        type="image/jpeg"/>
                                <img src={`${ASSETS}/img/banner-logo/banner-logo@1x.png`} alt="logo-desktop"/>
                            </picture>
                        </NavLink>
                        <NavLink to={`${SLASH_ROUTE}${activeLang}`} className="logo-item mobile">
                            <picture>
                                <source
                                        srcSet={`${ASSETS}/img/mobil-menu-logo-1x.png 1x, ${ASSETS}/img/mobil-menu-logo-2x.png 2x`}
                                        type="image/jpeg"/>
                                <img src={`${ASSETS}/img/mobil-menu-logo-1x.png`} alt="logo-mobile-menu"/>
                            </picture>
                        </NavLink>

                        {
                            headerData &&
                            <div className="menu-container" onClick={this.toggleMenu} ref={this.noScroll}>
                                <nav className="menu-block top">
                                    <ul>
                                        {
                                            headerData.menuItems.map((menuItem,key)=>
                                                <li key={key} onClick={(e)=>{

                                                    if(!menuItem.link){
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.changeRequestValue(true)

                                                    }
                                                }}>
                                                    {
                                                        menuItem.link ?
                                                            <Link to={`${SLASH_ROUTE}${activeLang}/${menuItem.link}`}>{menuItem.name}</Link>
                                                            :
                                                            menuItem.name
                                                    }
                                                </li>
                                            )
                                        }
                                        <li onClick={this.changeLang}>{activeLang === "tr" ?"EN" : "TR"}</li>
                                    </ul>

                                </nav>
                                {/*//TODO:aşağıdaki mobile-menu-container 'opened' classı gelecek mobilde açılması için. */}
                                <div className="mobile-menu-container">
                                    <div className="bar1"></div>
                                    <div className="bar2 "></div>
                                    <div className="bar3"></div>
                                </div>
                                <HeaderTagsComponent history={this.props.history} changeLang={this.changeLang}/>
                            </div>
                        }


                    </div>
                </div>
                {
                    isRequested &&
                    <RequestCatalog changeRequestValue={this.changeRequestValue}/>
                }
            </div>
        );
    }
}
const mapStateToProps = (state,ownProps) => {
    let articleLink = ownProps.location.pathname.split(ARTICLE_DETAIL_WITHOUT_PARAM)[1]
    return({
        menuItems: state.homeReducer[state.langReducer.activeLang] && state.homeReducer[state.langReducer.activeLang].menuItems ? state.homeReducer[state.langReducer.activeLang].menuItems : [],
        activeLang: state.langReducer.activeLang,
        headerData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].header:null,
        isOtherLangExits: articleLink && state.articleDetailReducer[articleLink] ? state.articleDetailReducer[articleLink].isOtherLangExist : true
    });
}
export default connect(mapStateToProps)(HeaderComponent);
