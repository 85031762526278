import React, {Component} from "react";
import {connect} from "react-redux";
import ArticleListItemComponent from "../../components/articleList/articleListItemComponent";
import InfiniteScroll from 'react-infinite-scroll-component';
import {articleListFetchDataAction} from "../../actions"
import InfinitySliderTextComponent from "../../components/common/infinitySliderTextComponent";

class ArticleListContainer extends Component {

    activeIndex = 1;
    fetchData = () => {
        this.props.dispatch(articleListFetchDataAction({"index": this.activeIndex, "tag": this.props.tag_link,"lang":this.props.activeLang,"searchTerm":this.props.filterObj.searchTerm,"sortBy":this.props.filterObj.sortBy}));

    }

    render() {
        const articleItems = this.props.articleListData;
        const hasMore = this.props.hasMore;
        const totalCount = this.props.totalCount;
        return (
            <div className="article-list-container safe-area scrollanim">
                {articleItems &&
                <InfiniteScroll
                    dataLength={totalCount}
                    next={this.fetchData}
                    hasMore={hasMore}
                >
                    {articleItems.map((article, key) =>
                        <ArticleListItemComponent min={this.props.min} activeLang={this.props.activeLang} key={article.id}
                                                  article={article}/>
                    )}
                </InfiniteScroll>
                }

            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    articleListData: state.articleListReducer.articleList && state.articleListReducer.articleList[ownProps.tag_link] ? state.articleListReducer.articleList[ownProps.tag_link].contentArticle.result : null,
    hasMore: state.articleListReducer.articleList && state.articleListReducer.articleList[ownProps.tag_link] ? state.articleListReducer.articleList[ownProps.tag_link].contentArticle.hasMore : null,
    totalCount: state.articleListReducer.articleList && state.articleListReducer.articleList[ownProps.tag_link] ? state.articleListReducer.articleList[ownProps.tag_link].contentArticle.totalCount : null,
    activeLang: state.langReducer.activeLang,
    min: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].common.min:null,
    filterObj: state.articleListReducer.articleList ? state.articleListReducer.filterObj || {} : {}
});

export default connect(mapStateToProps)(ArticleListContainer);