import React, {Component} from 'react';
import {connect} from "react-redux";
import ArticleGalleryContainer from "./articleGalleryContainer";
import xss from "xss";
import {xssWhiteList} from "../../helpers";

class ArticleContentContainer extends Component {
    getRendered = (stageItem, key, stageIndex) => {
        switch (stageItem.content_type_id) {
            case 2:
                return <ArticleGalleryContainer key={key+'-'+stageIndex} {...stageItem}/>
            default:
                return <div key={key + '-' + stageIndex} className="info-text-item" dangerouslySetInnerHTML={{__html: xss(stageItem.content_text, xssWhiteList)}}/>
        }
    }

    render() {
        let {contents} = this.props;
        return (
            contents.map((contentItem, key) =>
                <div key={key}>
                    {contentItem.text &&
                    <div className="info-text-item" dangerouslySetInnerHTML={{__html: contentItem.text}}/>
                    }
                    {
                        contentItem.description &&
                        <div className="info-text-item" dangerouslySetInnerHTML={{__html: contentItem.description}}/>

                    }
                    {contentItem.article_stages && contentItem.article_stages.map((stageItem, stageIndex) =>
                        this.getRendered(stageItem, key,stageIndex)
                    )}
                </div>
            )

        )
    }
};

const mapStateToProps = (state, ownProps) => ({
    contents: state.articleDetailReducer[ownProps.articleLink] ? state.articleDetailReducer[ownProps.articleLink].articles_headings : [],
})

export default connect(mapStateToProps)(ArticleContentContainer);
