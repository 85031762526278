import * as actionTypes from "../constants/actionTypes"
import {getArticleListData, saveCV, submitForm,submitRequestCatalogForm} from "../services/webService"
import {CHANGE_HOVER_IMG} from "../constants/actionTypes";

const homeData = (data) => ({
    type: actionTypes.GET_HOME_DATA,
    payload: data
});

const articleListData = (data) => ({
    type: actionTypes.GET_ARTICLE_LIST_DATA,
    payload: data
});
const articleListFetchData = (data,isFirst,tag, filterObj) => ({
    type: actionTypes.GET_ARTICLE_LIST_FETCH_DATA,
    payload: data,
    isFirst,
    tag,
    filterObj
});

export const articleListFetchDataAction =(params)=>dispatch=>{
    getArticleListData(params).then((result)=>{
        dispatch(articleListFetchData(result.data.success,params.index==0,params.tag,{searchTerm:params.searchTerm,sortBy:params.sortBy}))
    })
}
export const submitFormAction =(params,cb)=>dispatch=>{
    submitForm(params).then((res)=>{
        if (res.data.success) {
            cb(true);
        }else{
            cb(false);
        }
    })
}
export const saveCVAction = (cv,recatchValidation) => dispatch => {
    return saveCV(cv,recatchValidation);
};

export const submitRequestCatalogFormAction =(params,cb)=>dispatch=>{
    submitRequestCatalogForm(params).then((res)=>{
        if (res.data.success) {
            cb(true)
        }else{
            cb(false)
        }
    })
}

export const resetArticleListFilter = () => ({
    type: actionTypes.RESET_ARTICLE_FILTER,
});

export const changeHoverImageAction = (payload) => ({
    type: CHANGE_HOVER_IMG,
    payload
})