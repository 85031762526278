import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";

class CertificateInfoContainer extends Component{
    render() {
        return (
            <div className="company-profile-block">
                <div className="img-block">
                    <figure className="img-item">
                        <picture>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.jpg 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.jpg 3x`}
                                type="image/jpeg"/>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.webp 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.webp 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.webp 3x`}
                                type="image/webp"/>
                            <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x`}
                                 alt="article-list"/>
                        </picture>
                    </figure>
                </div>
                <div className="description-item">
                    <h2>Our Quality & Sustainability Certificates</h2>
                    <p>Ünlü Tekstil’s management system, environment management system, worker
                        health and work safety management system and information security management
                        system are approved by ISO and TÜRKAK standards. Our company is the holder
                        of OHSAS 18001:2007, ISO 27001:2013, ISO 14001:2015, ISO 9001:2015,
                        CU833281OCS-03.2017 standards.
                    </p>
                    <p>In addition, our company has been certified with OCS 100 CERTIFICATE by
                        Control Union as an ORGANIC MANUFACTURER.</p>
                    <p>Besides, each and every year, our company is subject to audits by
                        international independent auditing organizations such as SEDEX and BSCI in
                        terms of social compliance and code of conduct.</p>
                </div>
            </div>
        );
    }
}

export default CertificateInfoContainer;