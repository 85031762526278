import React, {Component} from 'react';
import {connect} from "react-redux";
import BannerComponent from "../components/home/banner/bannerComponent";
import KnowledgeContainer from "../containers/home/knowledge/knowledgeContainer";
import InnovationComponent from "../components/home/innovations/innovationComponent";
import ContentSliderComponent from "../components/home/content/contentSliderComponent";
import PageService from "../pageServices/pageService";
import {setDocumentTitle} from "../helpers/index";
import {matchPath} from "react-router-dom";
import {HOME} from "../constants/routePaths";

class HomePage extends Component {
    componentDidMount() {
        this.getData();
        if(this.props.metas && this.props.metas.title){
            setDocumentTitle(this.props.metas.title);
        }

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.metas !== this.props.metas){
            if(nextProps.metas && nextProps.metas.title){
                setDocumentTitle(nextProps.metas.title);
            }
        }

    }

    getData = () => {

        let urlParams = matchPath(this.props.location.pathname, {
            path: HOME,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch,urlParams.params);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.getData();
        }
    }

    render() {
        return (
            <div>
                <h1 style={{position:"absolute",visibility: "hidden",opacity:0}}>{this.props.h1title?this.props.h1title:"Ünlü Tekstil Anasayfa"}</h1>
                <BannerComponent/>
                <InnovationComponent/>
                <ContentSliderComponent/>
                <KnowledgeContainer/>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    metas: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].metas : {},
    h1title: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].h1title : null,
});

export default connect(mapStateToProps)(HomePage);
