import React, {Component} from 'react';
import CopyrightComponent from "./copyrightComponent";
import SocialMediaComponent from "./socialMediaComponent";
import ContactComponent from "./contactComponent";
import MenuListComponent from "./menuListComponent";
import FooterLeftSideComponent from "./footerLeftSideComponent";
import {addCursorClass, removeCursorClass} from "../../../helpers";

class FooterComponent extends Component {
   /* onEnter = () => {
        addCursorClass();
    }
    onLeave = () => {
        removeCursorClass()
    }

    componentWillUnmount() {
        removeCursorClass()
    }*/
    render() {
        return (
            <footer className="footer-root" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                <div className="footer-wrapper">
                    <div className="footer-container">
                        <div className="footer-top-block">
                            <FooterLeftSideComponent/>
                            <MenuListComponent/>
                        </div>
                        <div className="footer-bottom-block">
                            <div className="contact-block">
                                <ContactComponent/>
                                <SocialMediaComponent/>
                            </div>
                        </div>
                       <CopyrightComponent/>
                    </div>
                </div>
            </footer>
        );
    }
}


export default FooterComponent;
