import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import ContentPlayButtonComponent from "./ContentPlayButtonComponent";
import {Link} from "react-router-dom";
import LazyLoad from "react-lazyload";
import IMG from "../../../components/common/lazyComponent";
import {addCursorConClass, removeCursorConClass} from "../../../helpers";
import {changeHoverImageAction} from "../../../actions";
import {connect} from "react-redux";
class ContentVideoItemComponent extends Component {
    /*onEnter = () => {
        this.props.dispatch(changeHoverImageAction("play"));
        addCursorConClass("show-play");
    }
    onLeave = () => {
        removeCursorConClass("show-play")
    }

    componentWillUnmount() {
        removeCursorConClass("show-play")
    }*/
    render() {
        const {video,desc,setSelectedVideo} = this.props;
        return (
            <div onClick={()=>{
                setSelectedVideo(this.props)
            }} className="video-block">
                <figure className="video-item" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                    <LazyLoad placeholder={<IMG/>}>
                        {/*<ContentPlayButtonComponent/>*/}
                    <picture>
                        <source
                            srcSet={`${ASSETS}${video} `}
                            type="image/webp"/>
                        <source
                            srcSet={`${ASSETS}${video} `}
                            type="image/png"/>
                        <img src={`${ASSETS}${video} `} alt=""/>
                    </picture>
                    </LazyLoad>
                </figure>
                <p>{desc}</p>
            </div>
        );
    }
}

export default connect()(ContentVideoItemComponent);
