import React, {Component} from 'react';
import ContentArrowComponent from "../home/content/contentArrowComponent";
import {getElementWidth} from "../../helpers";
import RangeItemComponent from "./rangeItemComponent";

class MobileSliderComponent extends Component {
    sliderContainer = React.createRef();
    contentSliderContainer = React.createRef();
    state = {
        activeIndex: 0
    }
    itemWidth=0;
    showingCount = 2;
    componentDidMount() {
        if (typeof window !== "undefined") {
            this.itemWidth = getElementWidth(this.sliderContainer.current.children[0]);
            this.showingCount = Math.floor((window.innerWidth/ this.itemWidth)+0.2)
            this.forceUpdate();
            window.addEventListener('resize', this.setItemWidth);
            this.contentSliderContainer.current.addEventListener('touchmove',this.handleTouchMove, false);
            this.contentSliderContainer.current.addEventListener('touchstart',this.handleTouchStart, false);
        }
    }
    handleTouchStart=(evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
    };
    handleTouchMove=(evt) => {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }
        this.xUp = evt.touches[0].clientX;
        this.yUp = evt.touches[0].clientY;


        let xDiff = this.xDown - this.xUp;
        let yDiff = this.yDown - this.yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
            evt.preventDefault();
            if ( xDiff > 0 ) {
                this.changeActiveIndex(1);
            } else {
                this.changeActiveIndex(-1);
            }
        }
        this.xDown = null;
        this.yDown = null;
    };

    setItemWidth = () => {
        this.itemWidth = getElementWidth(this.sliderContainer.current.children[0]);
        this.showingCount = Math.floor((window.innerWidth / this.itemWidth)+0.2)
    }

    changeActiveIndex = (direction) => {
        if((this.props.activeIndex===0 && direction === -1) || (Math.ceil(this.props.children.length/this.showingCount)-1===this.props.activeIndex && direction===1)){
            return;
        }
        this.props.changeActiveIndex(direction)
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize', this.setItemWidth);
            this.contentSliderContainer.current.removeEventListener('touchmove',this.handleTouchMove, false);
            this.contentSliderContainer.current.removeEventListener('touchstart',this.handleTouchStart, false);
        }
    }

    render() {
        let {containerClassName,sliderClassName,isNavShow} = this.props
        let containerWidth = this.props.children.length*this.itemWidth;
        let translateValue = this.props.activeIndex*(this.showingCount*this.itemWidth);
        let windowInnerWidth = this.showingCount*this.itemWidth;
        return (
                <div className={containerClassName?containerClassName:"image-outer-container"} style={isNavShow?{}:{transform:`translate(${(containerWidth - windowInnerWidth)>translateValue || translateValue===0?-translateValue:-(containerWidth - windowInnerWidth)}px,0)`}} ref={this.contentSliderContainer}>
                    <div className={sliderClassName?sliderClassName:"image-container"} style={!isNavShow?{}:{transform:`translate(${(containerWidth - windowInnerWidth)>translateValue || translateValue===0?-translateValue:-(containerWidth - windowInnerWidth)}px,0)`}} ref={this.sliderContainer}>
                        {this.props.children}

                    </div>
                    {
                        isNavShow &&

                        <div className="nav-block light-color">
                            <RangeItemComponent pageSize={Math.ceil(this.props.children.length/this.showingCount)} activeIndex={this.props.activeIndex}/>
                            {
                                this.props.children.length/this.showingCount > 1 &&
                                <ContentArrowComponent isPreviousPassive={0===this.props.activeIndex} isNextPassive={Math.ceil(this.props.children.length/this.showingCount)-1===this.props.activeIndex} changeActiveIndex={this.changeActiveIndex}/>
                            }
                        </div>
                    }
                </div>
        )
    }
};

export default MobileSliderComponent;