import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import SliderComponent from "../../../components/common/sliderComponent";
import ContentVideoItemComponent from "../../../components/home/content/contentVideoItemComponent";
import ContentPlayButtonComponent from "../../../components/home/content/ContentPlayButtonComponent";
import {connect} from "react-redux";
import ContentImgItemComponent from "../../../components/home/content/contentImgItemComponent";
import {Link} from "react-router-dom";
import HoverListComponent from "../../../components/common/hoverListComponent";
import ScrollControlContainer from "../scrollControlContainer";
import {ARTICLE_LIST_WITHOUT_PARAM, HOME, SLASH_ROUTE, SUSTAINBILITY} from "../../../constants/routePaths";
import {withRouter} from "react-router-dom";
import {getElementPosition} from "../../../helpers";

class MultipleContentContainer extends Component {
    containerRef=React.createRef();
    componentDidMount() {
        if(this.props.location.pathname===SUSTAINBILITY && this.props.isSustainbility===true){
            //TODO : BURASI TİMEOUT TAN ÇIKARILACAK
           // setTimeout(this.scrollToElement,500);

        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname!==HOME && this.props.isSustainbility===true ) {
        //this.scrollToElement()
        }
    }

    scrollToElement = () => {
        let elementPosition = getElementPosition(this.containerRef.current);
         window.scrollTo({
             top: elementPosition.y -112,
             left: 0,
             behavior: 'smooth'
         });
};
    render() {
        const {title, area,activeLang,link,slideItems} = this.props;
        return (
            <ScrollControlContainer class={"content-slider-block multiple"}>
                <div className="head-block" ref={this.containerRef}>
                    <h2>{title}</h2>
                    <div className="link-item">
                        <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}${SLASH_ROUTE}${link}`}>
                            <div className="line"></div>
                            {/*<div className="arrow-item" onClick={() => {*/}
                            {/*clearBannerInterval();*/}
                            {/*changeDirection(-1);*/}
                            {/*}}>*/}
                            {/*<div className="arrow-border"></div>*/}
                            {/*<div className="arrow">*/}
                            {/*<HoverListComponent textValue={"Discover"}/>*/}
                            {/*<i className="icon-banner-arrow"/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            <span>{area.btn}</span>
                        </Link>
                    </div>

                </div>
                {
                    (slideItems && slideItems.length > 0) &&
                    <SliderComponent>
                        {
                            slideItems.map((item, key) =>
                                <ContentImgItemComponent lang={activeLang} key={key} {...item}/>
                            )
                        }
                    </SliderComponent>
                }
            </ScrollControlContainer>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    let slideItems = null;
    switch (ownProps.index) {
        case 0:
            slideItems = state.homeReducer.sustainability;
            break;
        case 1:
            slideItems = state.homeReducer.digitalization;
            break;
        case 2:
            slideItems = state.homeReducer.education;
            break;
        case 3:
            slideItems = state.homeReducer.agile;
            break;
    }
    return({
        area: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].area : [],
        sustainability: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].sustainability : [],
        activeLang: state.langReducer.activeLang,
        items: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].items : [],
        slideItems: slideItems
    });
}
export default withRouter(connect(mapStateToProps)(MultipleContentContainer));
