import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import LazyLoad from "react-lazyload";
import IMG from "../../components/common/lazyComponent";
class ArticlePictureContainer extends Component{
    render() {
        let {image,title,mobile_image,mobile_image_2X,mobile_image_3X} = this.props;
        return (
            <div className="image-block main-image">
                <div className="image-items">
                    <div className="image-item">
                        {
                            image &&
                            <LazyLoad placeholder={<IMG/>}>
                            <picture>
                                <source
                                    media="(min-width:421)"
                                    srcSet={`${ASSETS}${image}`}
                                        type="image/jpeg"/>
                                <source
                                    media="(min-width:421)"
                                    srcSet={`${ASSETS}${image.substring(0, image.lastIndexOf("."))}.webp`}
                                    type="image/webp"/>
                                <source
                                    media="(max-width:420)" /*mobile image*/
                                    srcSet={`${ASSETS}${mobile_image.substring(0, mobile_image.lastIndexOf("."))}.webp 1x, ${mobile_image_2X ? `${ASSETS}${mobile_image_2X.substring(0, mobile_image_2X.lastIndexOf("."))}.webp 2x , `:""}${mobile_image_3X ? `${ASSETS}${mobile_image_3X.substring(0, mobile_image_3X.lastIndexOf("."))}.webp 3x `:""} `}
                                    type="image/webp"/>
                                <source
                                    media="(max-width:420)" /*mobile image*/
                                    srcSet={`${ASSETS}${mobile_image} 1x, ${mobile_image_2X ? `${ASSETS}${mobile_image_2X} 2x, `:"" } ${mobile_image_3X ? `${ASSETS}${mobile_image_3X} 3x `:"" }  `} type="image/jpeg"/>
                                <img src={`${ASSETS}${image}`} alt={title}/>
                            </picture>
                            </LazyLoad>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,ownProps) => ({
    image:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].image:null,
    mobile_image:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].mobile_image:null,
    mobile_image_2X:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].mobile_image_2X:null,
    mobile_image_3X:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].mobile_image_3X:null,
    title:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].title:null
})

export default connect(mapStateToProps)(ArticlePictureContainer);
