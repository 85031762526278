import { act } from "react-dom/test-utils";
import {GET_ARTICLE_TAGS_DATA} from "../constants/actionTypes";


const initialState = {};

const articleListTagsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_ARTICLE_TAGS_DATA:
            return {
                ...state,
                articleListTags: [...action.payload],
            };
        default:
            
            return state;
    }
};

export default articleListTagsReducer;