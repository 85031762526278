import async from "async";
import * as WebService from '../services/webService';
import * as actionTypes from "../constants/actionTypes";

const articleListTagData = (data) => ({
    type: actionTypes.GET_ARTICLE_TAGS_DATA,
    payload: data
});

const layoutData = (data) => ({
    type: actionTypes.GET_LAYOUT_DATA,
    payload: data
});

export const getAllPageService = (dispatch,params) => {
    return new Promise((resolve,failure)=>{
        async.parallel([
            (cb) => {
                WebService.getArticleTagListData()
                    .then((res) => {
                        if (res.data.success) {
                            cb(null,res.data.success);
                        }
                    })
                    .catch((err) => {
                        cb(null,[]);
                    })
            },
            (cb) => {
                WebService.getLayoutData()
                    .then((res) => {
                        if (res.data) {
                            cb(null,res.data);
                        }
                    })
                    .catch((err) => {
                        cb(null,[]);
                    })
            }
        ],(err,result)=>{
            dispatch(articleListTagData(result[0]));
            dispatch(layoutData(result[1]));
            resolve(result);
        });
    });


};