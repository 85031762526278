import React, {Component} from 'react';
import HoverImageComponent from "./hoverImageComponent";
import {connect} from "react-redux";

class CursorComponent extends Component{
    cursor = React.createRef();
    cursorLazy = React.createRef();
    lastScrolledTop = 0;
    componentDidMount() {
        if(typeof window!=="undefined"){
            document.addEventListener('mousemove', this.moveAt);
            document.addEventListener('scroll', this.scrollingPage);
        }
    }


    moveAt = (e) => {
        let willTopSet = (e.pageY <= document.body.scrollHeight+110);
        let x = e.pageX
        let y = e.pageY

        if(e.pageY<=document.body.scrollHeight-110){
            document.querySelector(".cursor-con .cursor-block").style.display=null;

        }else{
            document.querySelector(".cursor-con .cursor-block").style.display="none";
        }
        if(e.pageY<=document.body.scrollHeight-10){
            document.querySelector(".cursor-con .cursor-lazy").style.display=null;
        }else{
            document.querySelector(".cursor-con .cursor-lazy").style.display="none";

        }
        if (this.cursor.current) {
            if(willTopSet){
                // this.cursor.current.style.top = `${y - 30}px`;
                if(document.body.scrollHeight+60<e.pageY){
                    this.cursor.current.style.top = `${y - 60}px`;
                }else{
                    this.cursor.current.style.top = `${y - 30}px`;
                }
            }
            this.cursor.current.style.left = `${x - 30}px`;
        }
        if (this.cursorLazy.current) {
            if(willTopSet) {
                this.cursorLazy.current.style.top = `${y + 1}px`;
            }
            this.cursorLazy.current.style.left = `${x + 1}px`;
        }
    }

    scrollingPage = () => {
        let yMousePos = this.cursorLazy.current.style.top.replace("px",'');
        if(this.lastScrolledTop != window.scrollY){
            yMousePos -= this.lastScrolledTop;
            this.lastScrolledTop = window.scrollY;
            yMousePos += this.lastScrolledTop;
        }
        if(yMousePos <= document.body.scrollHeight+110){
        this.cursorLazy.current.style.top = `${yMousePos}px`;
        this.cursor.current.style.top = `${yMousePos - 30}px`;
        }
    }

    componentWillUnmount() {
        if(typeof window!=="undefined"){
            document.removeEventListener('mousemove', this.moveAt);
            document.removeEventListener('scroll', this.scrollingPage);
        }
    }
    /*TODO : show-play classı gelecek cursor-con'a*/
    render() {
        let {hoverImg}=this.props;
        return(
            <div className="cursor-con" id={"cursorCon"}>
                <div className="cursor-block" ref={this.cursor}>
                    <div className="arrow-item">
                        <div className="arrow-border"></div>
                        <HoverImageComponent img={`/img/${hoverImg}-1x.png`} img2x={`/img/${hoverImg}-2x.png`}/>
                        <div className="arrow">
                            <i className={`${hoverImg!=="play"?(hoverImg==="view"?"icon-banner-arrow":"icon-banner-arrow"):"icon-play"}`}/>
                        </div>
                    </div>
                </div>
                <div id={"cursorLazy"} className="cursor-lazy" ref={this.cursorLazy}>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    hoverImg: state.layoutReducer.hoverImg
});

export default connect(mapStateToProps)(CursorComponent);
