import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import CorporateYearContainer from "../../components/corporate/corporateYearContainer";

class CorporateHistoryContainer extends Component{
    render() {
        let {growData} = this.props;
        return(
            growData ?
            <section className="history-block">
                <div className="description-item">
                    <h2>{growData.title}</h2>
                    <p>{growData.desc}</p>
                </div>
                {
                    growData.items.map((item,key)=>
                        <CorporateYearContainer key={key} {...item}/>
                    )
                }
            </section>
                :
                <div></div>
        )
    }
};

const mapStateToProps = (state) => ({
    growData: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].grow:null
});

export default connect(mapStateToProps)(CorporateHistoryContainer);