import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {matchPath, NavLink} from "react-router-dom";
import PageService from "../../pageServices/pageService";
import {ARTICLE_DETAIL, CORPORATE, HOME} from "../../constants/routePaths";
import {connect} from "react-redux";
import CorporateQualityContainer from "../../containers/corporate/corporateQualityContainer";
import CorporateQualityTextContainer from "../../containers/corporate/corporateQualityTextContainer";
import CorporateSubLinkContainer from "../../containers/corporate/corporateSubLinkContainer";
import CorporateSubLinkMobileContainer from "../../containers/corporate/corporateSubLinkMobileContainer";
import CorporateHistoryContainer from "../../containers/corporate/corporateHistoryContainer";
import ValuesOfUnluContainer from "../../containers/corporate/valuesOfUnluContainer";
import OurExportPolicyContainer from "../../containers/corporate/ourExportPolicyContainer";
import CompanyHRContainer from "../../containers/corporate/companyHRContainer";
import TrainingContainer from "../../containers/corporate/trainingContainer";
import CertificatesContainer from "../../containers/corporate/certificatesContainer";
import CertificateInfoContainer from "../../containers/corporate/certificateInfoContainer";
import MissionVisionContainer from "../../containers/corporate/missionVisionContainer";
import InformationSocietyServices from "../../containers/corporate/informationSocietyServices";
import {getElementPosition, isScrolledIntoView} from "../../helpers";
import {setDocumentTitle} from "../../helpers/index";
class Corporate extends Component {
    clickedMenu = false;
    scrollLink;

    componentDidMount() {
        let urlParams = matchPath(this.props.location.pathname, {
            path: CORPORATE,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch,urlParams.params);
        if(this.props.match.params.subLink){
            this.scrollToSubLink();
        }
        if(typeof window!== "undefined"){
            window.addEventListener("scroll",this.scrollingPage)
        }
        if(this.props.metas && this.props.metas.title){
            setDocumentTitle(this.props.metas.title);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.scrollLink!==this.props.location.pathname && this.props.match.params.subLink!==prevProps.match.params.subLink) || this.props.links.length !== prevProps.links.length){
            this.scrollToSubLink();
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.metas !== this.props.metas){
            if(nextProps.metas && nextProps.metas.title){
                setDocumentTitle(nextProps.metas.title);
            }
        }

    }

    scrollToSubLink = () => {
        let index =this.props.links.findIndex(item=>item.link===this.props.match.params.subLink);
        if(typeof window !== "undefined" && index!==-1){
            //TODO: SetTimeout'suz bakilacak
            setTimeout(()=>{
                window.scrollTo({
                    left:0,
                    top:getElementPosition(document.getElementById("contents-"+index)).y,
                    behavior: 'smooth'
                })
            },150)
        }
    }


    scrollingPage = () => {
        if (this.props.links && this.clickedMenu !== true) {
            this.props.links.map((item, index) => {
                    let element = document.getElementById("contents-"+index);
                    if (item.link && element && isScrolledIntoView(element)) {
                        let locationArr = this.props.history.location.pathname.split("/");
                        if (this.props.history.location.pathname !== "/" + locationArr[1] + "/" + locationArr[2] + "/" + item.link) {
                            this.scrollLink = "/" + locationArr[1] + "/" + locationArr[2] + "/" +item.link
                            this.props.history.replace(this.scrollLink);
                        }
                    }
                    return item;
                }
            )
        }
    };

    componentWillUnmount() {
        if(typeof window!== "undefined"){
            window.removeEventListener("scroll",this.scrollingPage)
        }
    }

    render() {
        return (
            <div>
                <div className="corporate-root">
                    <div className="corporate-wrapper">
                        <CorporateQualityContainer/>
                        <div className="corporate-container safe-area">
                            <CorporateQualityTextContainer/>
                            <CorporateSubLinkContainer location={this.props.location} history={this.props.history} match={this.props.match}/>
                            <CorporateSubLinkMobileContainer match={this.props.match}/>
                            <CorporateHistoryContainer/>
                            <section className="company-profile-container">
                                <ValuesOfUnluContainer/>
                                {/*<OurExportPolicyContainer/>
                                <CompanyHRContainer/>
                                <TrainingContainer/>
                                <CertificateInfoContainer/>*/}
                                <CertificatesContainer/>
                                <MissionVisionContainer/>

                                <InformationSocietyServices/>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    links: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].links:[],
    metas: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].metas:[]
});

export default connect(mapStateToProps)(Corporate);

