import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import {ARTICLE_DETAIL_WITHOUT_PARAM, SLASH_ROUTE} from "../../../constants/routePaths";
import {Link} from "react-router-dom";

class InnovationImgItemComponent extends Component {
    render() {
        const {list_image,title,list_image_2X,mobile_list_image,mobil_list_image_3X,mobile_list_image_2X,activeLang,content_link}=this.props;
        return (
            <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_DETAIL_WITHOUT_PARAM}${content_link}`} className="list-block">
                <figure className="list-item">
                    <picture>
                        <source
                            media="(min-width: 421px)"
                            srcSet={`${ASSETS}${list_image} 1x, ${list_image_2X ? `${ASSETS}${list_image_2X} 2x`:""} `}
                            type="image/jpeg"/>
                        <source
                            media="(min-width: 421px)"
                            srcSet={`${ASSETS}${list_image.substring(0, list_image.lastIndexOf(".")) + ".webp"} 1x,${list_image_2X ? `${ASSETS}${list_image_2X.substring(0, list_image_2X.lastIndexOf(".")) + ".webp"} 2x`:""} `}
                            type="image/webp"/>
                        <source
                            media="(max-width: 420px)"
                            srcSet={`${ASSETS}${mobile_list_image.substring(0, mobile_list_image.lastIndexOf(".")) + ".webp"} 1x,${mobile_list_image_2X ? `${ASSETS}${mobile_list_image_2X.substring(0, mobile_list_image_2X.lastIndexOf(".")) + ".webp"} 2x, `:"" } ${mobil_list_image_3X ? `${ASSETS}${mobil_list_image_3X.substring(0, mobil_list_image_3X.lastIndexOf(".")) + ".webp"} 3x `:"" } `}
                            type="image/webp"/>
                        <source
                            media="(max-width: 420px)"
                            srcSet={`${ASSETS}${mobile_list_image} 1x,${mobile_list_image_2X ? `${ASSETS}${mobile_list_image_2X} 2x, `:""} ${mobil_list_image_3X ? `${ASSETS}${mobil_list_image_3X} 3x` :""} `}
                            type="image/jpeg"/>
                        <img src={`${ASSETS}${list_image}`} alt="article-list"/>
                    </picture>
                </figure>
                <p>{title}</p>
            </Link>
        );
    }
}

export default InnovationImgItemComponent;
