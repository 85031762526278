import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
class ValuesOfUnluContainer extends Component {
    render() {
        let {contents} = this.props;
        return (
            contents ? contents.map((item, key) =>
                    <div id={"contents-"+(item.menuOrder !== null && item.menuOrder!==undefined?item.menuOrder:"unShowing"+key)} key={key} className="company-profile-block">
                        <div className="img-block">
                            <figure className="img-item">
                                <LazyLoad placeholder={<IMG/>}>
                                <picture>
                                    <source
                                        media="(max-width: 420px)"
                                        srcSet={`${ASSETS}${item.img_mobile1x} 1x, ${item.img_mobile2x ? `${ASSETS}${item.img_mobile2x} 2x`:""} , ${item.img_mobile3x ? `${ASSETS}${item.img_mobile3x} 3x`:""}`}
                                        type="image/jpeg"/>
                                    <source
                                        media="(max-width: 420px)"
                                        srcSet={`${ASSETS}${item.img_mobile1x ? item.img_mobile1x.substring(0,item.img_mobile1x.lastIndexOf('.'))+ ".webp" : ""}  1x, ${ASSETS}${item.img_mobile2x ? item.img_mobile1x.substring(0,item.img_mobile2x.lastIndexOf('.'))+ ".webp" : ""}  2x, ${ASSETS}${item.img_mobile3x ? item.img_mobile3x.substring(0,item.img_mobile3x.lastIndexOf('.'))+ ".webp" : ""}  3x`}
                                        type="image/webp"/>
                                    <source
                                        media="(min-width: 421px)"
                                        srcSet={`${ASSETS}${item.img1x} 1x, ${ASSETS}${item.img2x} 2x, ${ASSETS}${item.img3x} 3x`}
                                        //TODO: desktop image png basılıyor sadece
                                        type={`image/${item.img1x.indexOf('.png')>-1 ? "png": "jpeg" }`}/>
                                    <source
                                        media="(min-width: 421px)"
                                        srcSet={`${ASSETS}${item.img1x.substring(0, item.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img2x.substring(0, item.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${item.img3x.substring(0, item.img3x.lastIndexOf("."))}.webp 3x`}
                                        type="image/webp"/>
                                    <img src={`${ASSETS}${item.img1x}`}
                                         alt="article-list"/>
                                </picture>
                                </LazyLoad>
                            </figure>
                        </div>
                        <div className="description-item">
                            <h2>{item.title}</h2>
                            {
                                item.sub_text&&
                                <p>{item.sub_text}</p>
                            }
                            {
                                item.content &&
                                    <div className="content-item">
                                        <figure className="img-item">
                                            <picture>
                                                <source
                                                    media="(max-width: 420px)"
                                                    srcSet={`${ASSETS}${item.content.img1x} 1x, ${ASSETS}${item.content.img2x} 2x, ${ASSETS}${item.content.img3x} 3x`}
                                                    type="image/jpeg"/>
                                                <source
                                                    media="(max-width: 420px)"
                                                    srcSet={`${ASSETS}${item.content.img1x.substring(0, item.content.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.content.img2x.substring(0, item.content.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${item.content.img3x.substring(0, item.content.img3x.lastIndexOf("."))}.webp 3x`}
                                                    type="image/webp"/>
                                                <source
                                                    media="(min-width: 421px)"
                                                    srcSet={`${ASSETS}${item.content.img1x} 1x, ${ASSETS}${item.content.img2x} 2x`}
                                                    type="image/jpeg"/>
                                                <source
                                                    media="(min-width: 421px)"
                                                    srcSet={`${ASSETS}${item.content.img1x.substring(0, item.content.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.content.img2x.substring(0, item.content.img2x.lastIndexOf("."))}.webp 2x,`}
                                                    type="image/webp"/>
                                                <img src={`${ASSETS}${item.content.img1x}`}
                                                     alt="article-list"/>
                                            </picture>
                                        </figure>
                                        <div className="text-item">
                                            {
                                                item.content.descriptions.map((textItem,index)=>
                                                    <p key={key+'-'+index}>{textItem}</p>
                                                )
                                            }
                                        </div>
                                    </div>
                            }

                            {
                                item.descriptions &&
                                item.descriptions.map((descItem,subKey)=>
                                    <p key={key + '-' + subKey} dangerouslySetInnerHTML={{__html: descItem}}/>
                                )
                            }
                        </div>
                    </div>
                )
                :
                <div/>

        )
    }
}

const mapStateToProps = (state) => ({
    contents: state.corporateReducer.pageData ? state.corporateReducer.pageData[state.langReducer.activeLang].contents : null
});

export default connect(mapStateToProps)(ValuesOfUnluContainer);
