import React, {Component} from 'react';
import ScrollControlContainer from "../../containers/home/scrollControlContainer";
import {Link} from "react-router-dom";
import {addCursorConClass, removeCursorConClass} from "../../helpers/index";
import {changeHoverImageAction} from "../../actions/index";
import {connect} from "react-redux";

class InfinitySliderTextComponent extends Component {
    /*onEnter = () => {
        if(this.props.showHover){
            this.props.dispatch(changeHoverImageAction(this.props.center.toLowerCase()));
            addCursorConClass("show-play");
            addCursorConClass("article-cursor");
        }
    }*/
    /*onLeave = () => {
        removeCursorConClass("show-play")
        removeCursorConClass("article-cursor")
    }*/

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.center !== this.props.center){
            // removeCursorConClass("show-play");
            // removeCursorConClass("article-cursor");
        }
    }

    componentWillUnmount() {
        this.props.dispatch(changeHoverImageAction("play"));
        // removeCursorConClass("show-play")
        // removeCursorConClass("article-cursor")
    }
    render() {
        const {knowledge,center,link}=this.props;
        return (
            !link ?
            <ScrollControlContainer class={"repeated-block"}  >
                <div className="repeated-item" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                    <div className="repeated-text">
                        <span>{knowledge}</span>
                        <span>{center}</span>
                        <span>{knowledge}</span>
                        <span>{center}</span>
                        <span>{knowledge}</span>
                        <span>{center}</span>
                        <span>{knowledge}</span>
                        <span>{center}</span>
                        <span>{knowledge}</span>
                        <span>{center}</span>
                        <span>{knowledge}</span>
                        <span>{center}</span>
                    </div>
                </div>

            </ScrollControlContainer>
                :
                <Link to={link}>
                    <ScrollControlContainer class={"repeated-block"}  >
                        <div className="repeated-item" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                            <div className="repeated-text">
                                <span>{knowledge}</span>
                                <span>{center}</span>
                                <span>{knowledge}</span>
                                <span>{center}</span>
                                <span>{knowledge}</span>
                                <span>{center}</span>
                                <span>{knowledge}</span>
                                <span>{center}</span>
                                <span>{knowledge}</span>
                                <span>{center}</span>
                                <span>{knowledge}</span>
                                <span>{center}</span>
                            </div>
                            <div className="btn-item">
                                <div className="regular-btn">
                                    <span>{knowledge}</span>
                                </div>
                            </div>
                        </div>

                    </ScrollControlContainer>
                </Link>
        );
    }
}

export default connect()(InfinitySliderTextComponent);
