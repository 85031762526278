import React, {Component,Fragment} from 'react';
import {Link} from "react-router-dom";
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
class ContactInformationContainer extends Component {
    isMobile = false
    constructor(props) {
        super(props);
        if(typeof window !== "undefined" && window.innerWidth>421){
            this.isMobile =false
        }else if(typeof window !== "undefined" && window.innerWidth<=420){
            this.isMobile =true
        }
    }
    checkIsMobile=()=>{
        if(typeof window !== "undefined"){
            if(window.innerWidth>421 && this.isMobile){
                this.isMobile = false
                this.forceUpdate()
            }
            else if(window.innerWidth<=420 && !this.isMobile){
                this.isMobile = true
                this.forceUpdate()
            }

        }
    }

    componentDidMount() {
        if(typeof window !== "undefined"){
            window.addEventListener('resize',this.checkIsMobile)
        }
        if(typeof window !== "undefined" && window.innerWidth>421){
            this.isMobile =false
        }else if(typeof window !== "undefined" && window.innerWidth<=420){
            this.isMobile =true
        }
        this.forceUpdate();
    }
    componentWillUnmount() {
        if(typeof window !== "undefined"){
            window.removeEventListener('resize',this.checkIsMobile)
        }
    }

    render() {
        let {contactData} = this.props;
        console.log(contactData);
        console.log(this.isMobile);
        return (
            <div>
                {
                    contactData &&
                    <div className="contact-info-block">

                        <div className="contact-left-block">
                            <div className="top-item">
                                <h3>{contactData.title}</h3>
                                <p>{contactData.address[0]}</p>
                                <p>{contactData.address[1]}</p>
                                <p>{contactData.address[2]}</p>
                                <div className="regular-btn light">
                                    <a href={contactData.directionLink} rel="noreferrer" target="_blank">{contactData.button}</a>
                                </div>

                            </div>
                            <div className="middle-item">
                                {
                                    contactData.phones &&  contactData.phones[0] &&
                                    <span>{contactData.phones[0]}</span>
                                }
                                {
                                    contactData.phones && contactData.phones.splice(1,contactData.phones.length).map((phoneItem,key)=>
                                        <a className={key === 0 ? "desc":''} href={`tel:${phoneItem.split(" ").join("")}`} rel="noreferrer">{phoneItem}</a>
                                    )
                                }
                                <span>{contactData.fax[0]}</span>
                                <a href="tel:+902164155467" rel="noreferrer">{contactData.fax[1]}</a>
                            </div>
                            <div className="bottom-item">
                                <p>{contactData.desc}</p>
                                {
                                    contactData.people.map((person,key)=>
                                        <Fragment key={key}>
                                            <span>{person.name}</span>
                                            {
                                                person.email &&
                                                <a href={`mailto:${person.email}`} rel="noreferrer">{person.email}</a>
                                            }
                                            {
                                                person.tel &&
                                                <a href={`tel:${person.tel.split(" ").join("")}`} rel="noreferrer">{person.tel}</a>
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        </div>

                        {
                            (typeof window!== "undefined" && contactData && contactData.items) &&
                            <div className="contact-right-block">
                                {
                                        contactData.items.filter(item=>item.isMobile===this.isMobile).map((img,index)=>
                                            <div className="img-item" key={img.id}>
                                                <picture>
                                                    {
                                                        !img.img3x &&
                                                        <source
                                                            media="(min-width: 421px)"
                                                            srcSet={`${ASSETS}${img.img1x.substring(0, img.img1x.lastIndexOf(".")) + ".webp"} 1x,${img.img2x ? `${ASSETS}${img.img2x.substring(0, img.img2x.lastIndexOf(".")) + ".webp"} 2x, `:"" } `}
                                                            type="image/webp"/>
                                                    }
                                                    {
                                                        !img.img3x &&
                                                        <source
                                                            media="(min-width: 421px)"
                                                            srcSet={`${ASSETS}${img.img1x} 1x,${img.img2x ? `${ASSETS}${img.img2x} 2x, `:""} `}
                                                            type="image/jpeg"/>
                                                    }
                                                    {
                                                        img.img3x &&
                                                        <source
                                                            media="(max-width: 420px)"
                                                            srcSet={`${ASSETS}${img.img1x} 1x, ${img.img2x ? `${ASSETS}${img.img2x} 2x`:""}, ${img.img3x ? `${ASSETS}${img.img3x} 3x`:""}`}
                                                            type="image/jpeg"/>
                                                    }
                                                    {
                                                        img.img3x &&
                                                        <source
                                                            media="(max-width: 420px)"
                                                            srcSet={`${ASSETS}${img.img1x.substring(0, img.img1x.lastIndexOf(".")) + ".webp"} 1x,${img.img2x ? `${ASSETS}${img.img2x.substring(0, img.img2x.lastIndexOf(".")) + ".webp"} 2x `:""}, ${img.img3x ? `${ASSETS}${img.img3x.substring(0, img.img3x.lastIndexOf(".")) + ".webp"} 3x`:""} `}
                                                            type="image/webp"/>
                                                    }

                                                    <img src={`${ASSETS}${img.img1x}`}
                                                         alt="article-list"/>
                                                </picture>
                                            </div>
                                        )
                                }

                            </div>
                        }

                    </div>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    contactData: state.contactReducer[state.langReducer.activeLang] ? state.contactReducer[state.langReducer.activeLang].contactData : null
});

export default connect(mapStateToProps)(ContactInformationContainer);
