import React, {Component} from 'react';
import {connect} from "react-redux";
import PageService from "../../pageServices/pageService";
import ArticleListSlideComponent from "./common/articleListSlideComponent";
import ArticleListContainer from "../../containers/articleList/articleListContainer";
import {matchPath} from "react-router-dom";
import {ARTICLE_LIST, ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../constants/routePaths";
import InfinitySliderTextComponent from "../../components/common/infinitySliderTextComponent";
import {resetArticleListFilter} from "../../actions";

class ArticleList extends Component {
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        let urlParams = matchPath(this.props.location.pathname, {
            path: ARTICLE_LIST,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        urlParams.params = {...urlParams.params,...this.props.filterObj}
        this.pageService.getData(this.props.dispatch,urlParams.params);
    }
    componentDidUpdate(prevProps){
        let _prevPath = prevProps.location.pathname;
        let _path = this.props.location.pathname;
        if(_prevPath !== _path){
            this.getData();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetArticleListFilter())
    }

    render() {
        let {articleTagsData, activeLang} = this.props;
        let activeIndex = articleTagsData.findIndex(item=>item.tag_link===this.props.match.params.tag_link);
        return (
            <div>
                <div className="article-list-root">
                    <ArticleListSlideComponent tag_link={this.props.match.params.tag_link} history={this.props.history} match={this.props.match}/>
                    <div className="article-list-wrapper ">
                        <ArticleListContainer  tag_link={this.props.match.params.tag_link} activeLang={activeLang}/>
                    </div>
                    {
                        articleTagsData.length>0 &&
                        <InfinitySliderTextComponent showHover={true} link={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${articleTagsData[(activeIndex+1)%articleTagsData.length].tag_link}`} knowledge={articleTagsData[(activeIndex+1)%articleTagsData.length].tag_name} center={articleTagsData[(activeIndex+1)%articleTagsData.length].tag_name}/>
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state)=>({
    articleTagsData : state.articleListTagsReducer.articleListTags ? state.articleListTagsReducer.articleListTags : [],
    activeLang: state.langReducer.activeLang,
    filterObj: state.articleListReducer ? state.articleListReducer.filterObj || {} : {}
});

export default connect(mapStateToProps)(ArticleList);