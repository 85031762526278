import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import {reducer as formReducer} from 'redux-form';
import corporateReducer from "./corporateReducer";
import articleListReducer from "./articleListReducer";
import contactReducer from "./contactReducer";
import articleListTagsReducer from "./articleListTagsReducer";
import langReducer from "./langReducer";
import articleDetailReducer from "./articleDetailReducer";
import layoutReducer from "./layoutReducer";
import privacyReducer from "./privacyReducer";
import cookieReducer from "./cookieReducer";
import productivityReducer from './productivityReducer';

const combineRed = combineReducers({
    homeReducer,
    corporateReducer,
    articleListReducer,
    articleListTagsReducer,
    layoutReducer,
    langReducer,
    articleDetailReducer,
    contactReducer,
    privacyReducer,
    cookieReducer,
    productivityReducer,
    form: formReducer,
});

export default combineRed
