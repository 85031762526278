import React, {Component} from 'react';
import {Link} from "react-router-dom";

class SocialMediaComponent extends Component {
    render() {
        return (
            <div className="social-media-item">
                <a href="https://twitter.com/unlutekstil?lang=en" target="_blank" rel="noreferrer" >
                    <i className="icon-twit"></i>
                </a>
                <a href="https://www.facebook.com/unlutekstilTR/?rf=320593077975290" target="_blank" rel="noreferrer" ><i className="icon-facebook"></i></a>
                <a href="https://www.youtube.com/channel/UCANr7OZw6wXaVJ0pUwsUz9A" target="_blank" rel="noreferrer" ><i className="icon-youtube"></i></a>
                <a href="https://www.instagram.com/unlutekstil/" target="_blank" rel="noreferrer"><i className="icon-instagram"  ></i></a>
                <a href="https://www.linkedin.com/company/unlu-tekstil/?originalSubdomain=tr" target="_blank" rel="noreferrer" ><i className="icon-linkedin"></i></a>
            </div>
        );
    }
}

export default SocialMediaComponent;