import {CHANGE_LANG} from "../constants/actionTypes";

const initialState = {activeLang:"tr"};

const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANG:
            return {
                ...state,
                activeLang: action.payload,
            };
        default:
            return state;
    }
};

export default langReducer;