import React,{Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment'

class ArticleInfoContainer extends Component{
    render() {
        let {duration,title,created_date,min,read} = this.props;
        return(
            <div className="head-block">
                <h1>{title}</h1>
                <div className="date-item">
                    <p>{moment(created_date).format("DD MMM YYYY")} • {duration} {min} {read}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    title:state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].title:null,
    duration: state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].duration:null,
    created_date: state.articleDetailReducer[ownProps.articleLink]?state.articleDetailReducer[ownProps.articleLink].created_date:null,
    min: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].common.min:null,
    read: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].common.read:null
})

export default connect(mapStateToProps)(ArticleInfoContainer);