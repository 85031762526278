import React from 'react';

const RenderSelectComponent = ({options,position,label,positions,input,meta:{touched, error, invalid, warning}}) => (
    <div className={touched && error ?"select-block t-2 error":"select-block t-2"}>
        <label htmlFor={label}>{label}</label>
        <select {...input}>
            <option value=''>{position}</option>
            {positions.map((pos,key)=><option key={key} value={pos}>{pos}</option>)}
        </select>
        <span className="gg-select"></span>
    </div>
);

export default RenderSelectComponent;
