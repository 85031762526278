import React, {Component} from 'react';

class RangeItemComponent extends Component{
    render() {
        let {pageSize, activeIndex} = this.props;
        return(
            <div className="range-item">
                <div className="range" style={{width:`${(100/(pageSize || 1 ))*(activeIndex+1)}%`}}></div>
            </div>
        )
    }
};

export default RangeItemComponent;
