import React, {Component} from 'react';
import HoverListComponent from "./hoverListComponent";
import {ASSETS} from "../../constants/paths";

class ArrowCircleLoopComponent extends Component {
    render() {
        const {arrowName, text} = this.props;
        return (

            <div className="arrow-item">
                <div className="arrow-border arr-border-dark"></div>

                <div className="arrow arr-color-dark">
                    {/*{*/}
                    {/*    text &&*/}
                    {/*    <HoverListComponent textValue={text}/>*/}
                    {/*}*/}
                    <i className="icon-crossed-arrow"/>
                </div>
                {
                    arrowName &&
                    <div>
                        <i className="icon-crossed-arrow-small"></i>
                        <span>{arrowName}</span>
                    </div>

                }
            </div>
        );
    }
}

export default ArrowCircleLoopComponent;
