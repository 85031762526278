import React, {Component} from 'react';
import ContactFormComponent from "../../components/formFields/contactFormComponent";
import ConversationComponent from "../../components/contact/conversationComponent";

class ConversationContainer extends Component {
    render() {
        return (
            <div className="conversation-block">

                <ConversationComponent/>
                <div className="form-item">
                    <ContactFormComponent/>
                </div>
            </div>
        );
    }
}

export default ConversationContainer;
