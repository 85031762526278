import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {CORPORATE_WITHOUT_LINK, SLASH_ROUTE} from "../../constants/routePaths";

class CorporateSubLinkMobileContainer extends Component{
    container = React.createRef();
    toggleElement = () => {
        this.container.current.classList.toggle("active")
    }
    render() {
        let {links,activeLang,match} = this.props;
        return(
            <div ref={this.container} className="company-list-item-mobile">
                {
                    links[0] &&
                    <div className="title-item" onClick={this.toggleElement}>
                        <h3>{links.find(item=>match.params.subLink===item.link)?links.find(item=>match.params.subLink===item.link).title:links[0].title}</h3>
                        <i className="icon-arrow-down"></i>
                    </div>
                }
                <div className="dropdown-item">
                    <ul>
                        {
                            links.map((linkItem,key)=>
                                <li key={key} className="first-list-item" onClick={this.toggleElement}>
                                    <NavLink to={`${SLASH_ROUTE}${activeLang}${CORPORATE_WITHOUT_LINK}/${linkItem.link}`}>{linkItem.title}</NavLink>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    links: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].links:[],
    activeLang: state.langReducer.activeLang
});

export default connect(mapStateToProps)(CorporateSubLinkMobileContainer);