import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {SLASH_ROUTE} from "../../../constants/routePaths";

class CopyrightComponent extends Component {
    render() {
        let {activeLang,footerData} = this.props;
        return (
            <div className="copyright-block">
                {
                    footerData &&
                    <div className="copyright-item">
                        <div className="left-item">
                            <p>{footerData.reserved}</p>
                        </div>
                        <div className="right-item">
                            <Link to={`${SLASH_ROUTE}${activeLang}/${footerData.cookie_link}`} rel="noreferrer" title="Cookie Policy">{footerData.cookie}</Link>
                            <Link to={`${SLASH_ROUTE}${activeLang}/${footerData.privacy_link}`} rel="noreferrer" title="Privacy Policy" >{footerData.privacy}</Link>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
    footerData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].footer:null
});

export default connect(mapStateToProps)(CopyrightComponent);
