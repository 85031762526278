import React, {Component} from 'react';
import KnowledgeImageComponent from "./knowledgeImageComponent";
import ScrollControlContainer from "../../../containers/home/scrollControlContainer";
import RangeItemComponent from "../../common/rangeItemComponent";
import ContentArrowComponent from "../content/contentArrowComponent";
import MobileSliderComponent from "../../common/mobileSliderComponent";
import {
    addCursorConClass,
    getElementWidth,
    removeCursorConClass
} from "../../../helpers";
import HoverImageComponent from "../../common/hoverImageComponent";
import {ACADEMY_ASSETS, ASSETS, SUSTAINABILITY_ASSETS} from "../../../constants/paths";

class KnowledgeComponent extends Component {
    contentSlider = React.createRef();
    state = {
        activeIndex: 0
    }

    /*onEnter = () => {
        addCursorConClass("hide-active");
    }
    onLeave = () => {
        removeCursorConClass("hide-active")
    }*/

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize', this.setItemWidth);
        }
        // removeCursorConClass("hide-active")
    }

    setItemWidth = () => {
        this.itemWidth = getElementWidth(this.contentSlider.current.sliderContainer.current.children[0]);
        this.showingCount = Math.floor((window.innerWidth / this.itemWidth) + 0.2)
        if(this.showingCount >= this.props.educations.length){
            this.setState({activeIndex:0})
        }else{
            this.forceUpdate();
        }
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.setItemWidth);
            this.itemWidth = getElementWidth(this.contentSlider.current.sliderContainer.current.children[0]);
            this.showingCount = Math.floor((window.innerWidth / this.itemWidth) + 0.2)
            this.forceUpdate();
        }
    }

    changeActiveIndex = (direction) => {
        if ((this.state.activeIndex === 0 && direction === -1) || (Math.ceil(3 / this.showingCount) - 1 === this.state.activeIndex && direction === 1)) {
            return;
        }
        this.setState({
            activeIndex: this.state.activeIndex + direction
        })
    }

    render() {
        const {classname, head, description, arrowName, img, view, link,educations,index} = this.props;
        return (
            <ScrollControlContainer class={`knowledge-block ${classname ? classname : ''}`}>
                <div className="knowledge-item-block">
                    <div className="text-block">
                        <div className="text-item">
                            <h3>{head}</h3>
                            <p>{description}</p>

                        </div>
                        <a onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} href={link} target="_blank" rel="noreferrer" className="arrow-item" >
                            <div className="arrow-border"></div>
                            <HoverImageComponent img={"/img/academy-1x.png"} img2x={"/img/academy-2x.png"}/>
                            <div className="arrow">
                                <i className="icon-crossed-arrow"/>
                                <span>{arrowName}</span>
                            </div>
                        </a>
                        <a href={link} target="_blank" rel="noreferrer" className="regular-btn">
                            <i className="icon-crossed-arrow-small"></i>
                            <span>{arrowName}</span>
                        </a>

                    </div>
                    {
                        educations &&
                        <MobileSliderComponent ref={this.contentSlider} activeIndex={this.state.activeIndex}
                                               changeActiveIndex={this.changeActiveIndex}>
                            {
                                educations.map((itemImg, key) =>
                                    <KnowledgeImageComponent
                                        //imgPath={index===0?ACADEMY_ASSETS:SUSTAINABILITY_ASSETS}
                                        imgPath={ASSETS}
                                        key={key} classname={key=== 0 ? 'first-item' : key === 1 ? 'second-item' : key===2 ? 'third-item' : ''} {...itemImg}/>
                                )
                            }
                        </MobileSliderComponent>
                    }

                </div>
                {
                    educations &&
                    <div className="nav-block">
                        <RangeItemComponent pageSize={Math.ceil(educations.length / this.showingCount)}
                                            activeIndex={this.state.activeIndex}/>
                        <ContentArrowComponent isPreviousPassive={0===this.state.activeIndex} isNextPassive={Math.ceil(this.props.educations.length/this.showingCount)-1===this.state.activeIndex} changeActiveIndex={this.changeActiveIndex}/>
                    </div>
                }

            </ScrollControlContainer>
        );
    }
}

export default KnowledgeComponent;
