import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {
    ARTICLE_LIST_WITHOUT_PARAM,
    CONTACT,
    CONTACT_FORM_LINK,
    JOB_APP_LINK,
    SLASH_ROUTE
} from "../../constants/routePaths";

import {matchPath, NavLink} from "react-router-dom";
import ContactFormComponent from "../../components/formFields/contactFormComponent";
import PageService from "../../pageServices/pageService";
import {connect} from "react-redux";
import ConversationContainer from "../../containers/contact/conversationContainer";
import ContactInformationContainer from "../../containers/contact/contactInformationContainer";
import NotFoundPage from "../notFoundPage";
import {setDocumentTitle} from "../../helpers/index";
import InfinitySliderTextComponent from "../../components/common/infinitySliderTextComponent";



class Contact extends Component {
    componentDidMount() {
        let urlParams = matchPath(this.props.location.pathname, {
            path: CONTACT,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch,urlParams.params);
        if(this.props.contactData.metas && this.props.contactData.metas.title){
            setDocumentTitle(this.props.contactData.metas.title);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.contactData.metas !== this.props.contactData.metas){
            if(nextProps.contactData.metas && nextProps.contactData.metas.title){
                setDocumentTitle(nextProps.contactData.metas.title);
            }
        }

    }
    render() {
        let {match,activeLang,articleTagsData} = this.props;
        let activeIndex = articleTagsData.findIndex(item=>item.tag_link===this.props.match.params.tag_link);
        return (
            <div>
                <h1 style={{position:"absolute",visibility: "hidden",opacity:0}}>{this.props.h1title?this.props.h1title:"Ünlü Tekstil İletişim"}</h1>
                {
                    (!match.params.formName || match.params.formName===JOB_APP_LINK || match.params.formName===CONTACT_FORM_LINK) ?
                    <div className="communication-root">
                        {
                            articleTagsData.length>0 &&
                            <InfinitySliderTextComponent  knowledge={activeLang==="tr"?"İletişim":"Contact"} center={activeLang==="tr"?"İletişim":"Contact"}/>
                        }
                        <div className="communication-wrapper">
                            <div className="communication-container safe-area">
                                <ContactInformationContainer/>
                                {
                                    articleTagsData.length>0 &&
                                    <InfinitySliderTextComponent  knowledge={activeLang==="tr"?"Görüşmeyi Başlat":"Get In Touch"} center={activeLang==="tr"?"Görüşmeyi Başlat":"Get In Touch"}/>
                                }
                                <ConversationContainer/>
                            </div>
                        </div>
                    </div>
                    :
                    <NotFoundPage {...this.props}/>
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    contactData: state.contactReducer[state.langReducer.activeLang] ? state.contactReducer[state.langReducer.activeLang].contactData : {},
    articleTagsData : state.articleListTagsReducer.articleListTags ? state.articleListTagsReducer.articleListTags : [],
    h1title: state.contactReducer[state.langReducer.activeLang] && state.contactReducer[state.langReducer.activeLang].contactData ? state.contactReducer[state.langReducer.activeLang].contactData.h1title : null,
    activeLang: state.langReducer.activeLang
});

export default connect(mapStateToProps)(Contact);
