import React, {Component} from 'react';
import ContentVideoItemComponent from "./contentVideoItemComponent";
import {connect} from "react-redux";
import SliderComponent from "../../common/sliderComponent";
import MultipleContentContainer from "../../../containers/home/content/multipleContentContainer";
import {Link} from "react-router-dom";
import {addCursorConClass, checkElementInViewPort, removeCursorConClass} from "../../../helpers";
import ScrollControlContainer from "../../../containers/home/scrollControlContainer";
import VideoModalComponent from "./videoModalComponent";
import HoverImageComponent from "../../common/hoverImageComponent";

class ContentSliderComponent extends Component {
    state = {
        selectedVideo:false
    };
    isMobile=false;
    constructor(props) {
        super(props);
        if (typeof window !== "undefined"){
            this.isMobile=window.innerWidth < 641;
        }
    };
    setSelectedVideo = (selectedVideo) => {
        this.setState({
            selectedVideo
        })
    };

   /* onEnter = () => {
        addCursorConClass("hide-active");
    }
    onLeave = () => {
        removeCursorConClass("hide-active")
    }*/

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener("resize",this.resizeVideoBlock);
        }

    }
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.addEventListener("resize",this.resizeVideoBlock);
        }
        // removeCursorConClass("hide-active")
    }
    resizeVideoBlock=()=>{
        if(window.innerWidth<641 && this.isMobile!==true){
            this.isMobile=true;
            this.forceUpdate();
        }else if(window.innerWidth>641 && this.isMobile!==false){
            this.isMobile=false;
            this.forceUpdate();
        }
    };
    getItems = () => {

    }
    render() {
        const {videos, items} = this.props;
        return (
            <div className="content-slider-root">
                <div className="content-slider-container">
                    <ScrollControlContainer class={"content-slider-block video-slider-block"}>
                            <div className="head-block">
                                <h2>{videos.title}</h2>
                                <a onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} href={videos.link} target="_blank" rel="noreferrer" className="arrow-item" >
                                    <div className="arrow-border"></div>
                                    <HoverImageComponent img={"/img/youtube-1x.png"} img2x={"/img/youtube-2x.png"}/>
                                    <div className="arrow">
                                        <div className="link-item">
                                            <a href={videos.link} target="_blank" rel="noreferrer" >
                                                <i className="icon-crossed-arrow"></i>
                                                <span>{videos.btn}</span>
                                            </a>
                                        </div>
                                    </div>
                                </a>


                            </div>
                            {
                                (videos.items && videos.items.length > 0) &&
                                <SliderComponent>
                                    {
                                        videos.items.slice(0,this.isMobile?3:videos.items.length).map((item, key) =>
                                            <ContentVideoItemComponent setSelectedVideo={this.setSelectedVideo} key={key} {...item}/>
                                        )
                                    }
                                </SliderComponent>
                            }
                        <a href={videos.link} className="btn-item" target="_blank" rel="noreferrer">
                            <div className="regular-btn">
                                <i className="icon-crossed-arrow-small"></i>
                                <span>Ünlü Youtube Channel</span>
                            </div>
                        </a>
                    </ScrollControlContainer>
                    {
                        items.map((item, key) =>
                            <MultipleContentContainer index={key} key={key} isSustainbility={key===0} {...item}/>
                        )
                    }

                </div>
                {
                    this.state.selectedVideo && <VideoModalComponent setSelectedVideo={this.setSelectedVideo} selectedVideo={this.state.selectedVideo}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].videos : [],
    items: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].items : []
});
export default connect(mapStateToProps)(ContentSliderComponent);
