import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import RangeItemComponent from "../../components/common/rangeItemComponent";
import ContentArrowComponent from "../../components/home/content/contentArrowComponent";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
import {getElementWidth} from "../../helpers";
import MobileSliderComponent from "../../components/common/mobileSliderComponent";

class CertificatesContainer extends Component {
    contentSlider = React.createRef();
    state = {
        activeIndex: 0
    }
    setItemWidth = () => {
        if (typeof window !== "undefined" && this.contentSlider.current.sliderContainer.current.children[0]) {
            this.itemWidth = getElementWidth(this.contentSlider.current.sliderContainer.current.children[0]);
            this.showingCount = Math.floor((window.innerWidth / this.itemWidth) + 0.2)
            this.forceUpdate();
        }
    }

    componentDidMount() {
        if (this.props.certificates.length > 0) {
            this.setItemWidth()
        }
        if(typeof window !== "undefined") {
            window.addEventListener('resize',this.resizingWindow);
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize',this.resizingWindow);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.certificates.length !== prevProps.certificates.length) {
            setTimeout(() => {
                this.setItemWidth()
            }, 150)
        }
    }
    resizingWindow = () => {
        if(window.innerWidth > 768){
             this.setState({
                 activeIndex:0
             });
        }
        this.setItemWidth();
    }
    changeActiveIndex = (direction) => {
        if ((this.state.activeIndex === 0 && direction === -1) || (Math.ceil(this.props.certificates.length / this.showingCount) - 1 === this.state.activeIndex && direction === 1)) {
            return;
        }
        this.setState({
            activeIndex: this.state.activeIndex + direction
        })
    }

    render() {
        let {certificates} = this.props;
        return (
            (certificates && certificates.length > 0) ?
            <MobileSliderComponent isNavShow={true} containerClassName={"certificate-outer-block"} sliderClassName={"certificate-block"}
                                   ref={this.contentSlider} activeIndex={this.state.activeIndex}
                                   changeActiveIndex={this.changeActiveIndex}>
                {
                    certificates.map((certificateItem, key) =>
                        <figure key={key} className="img-item">
                            <LazyLoad placeholder={<IMG/>}>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS}${certificateItem.img1x} 1x`}
                                        type="image/jpeg"/>
                                    <source
                                        srcSet={`${ASSETS}${certificateItem.img1x.substring(0, certificateItem.img1x.lastIndexOf("."))}.webp 1x`}
                                        type="image/webp"/>
                                    <img src={`${ASSETS}${certificateItem.img1x}`}
                                         alt="article-list"/>
                                </picture>
                            </LazyLoad>
                        </figure>
                    )
                }

            </MobileSliderComponent>
                : null

        )
    }
};


const mapStateToProps = (state) => ({
    certificates: state.corporateReducer.pageData ? state.corporateReducer.pageData[state.langReducer.activeLang].certificates : []
});

export default connect(mapStateToProps)(CertificatesContainer);
