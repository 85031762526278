import React, {Component} from 'react';
import {getElementPosition} from "../../../helpers";
import {connect} from "react-redux";
import InnovationImgItemComponent from "./innovationImgItemComponent";
import {Link} from "react-router-dom";
import SliderComponent from "../../common/sliderComponent";
import ContentVideoItemComponent from "../content/contentVideoItemComponent";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../../constants/routePaths";
import ScrollDownComponent from "../../common/scrollDownComponent";


class InnovationComponent extends Component {
    innovationRoot = React.createRef();
    innovationBlock = React.createRef();
    innovationTitle = React.createRef();
    innovationSlider = React.createRef();
    isMobileInovOpen=false;
    state={
        index:1,
        showCount:3,
        hasMore:true
    }
    componentDidMount() {
        if (typeof window !== "undefined") {
            this.isMobileInovOpen=window.innerWidth<641?true:false;
            this.forceUpdate();
            setTimeout(()=>{
                this.setTranslatePosition();
            },150)
            window.addEventListener("scroll", this.windowScroll);
            window.addEventListener("resize",this.resizeInovationBlock);
        }

    }
    resizeInovationBlock=()=>{
        if(window.innerWidth<641 && this.isMobileInovOpen!==true){
            this.isMobileInovOpen=true;
            this.forceUpdate();
        }else if(window.innerWidth>641 && this.isMobileInovOpen!==false){
            this.isMobileInovOpen=false;
            this.forceUpdate();
        }
    };
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('scroll', this.windowScroll);
            window.addEventListener("resize",this.resizeInovationBlock);
        }
    }
    windowScroll = (e) => {
        this.setTranslatePosition();
        e.stopPropagation();
    }

    setTranslatePosition = () => {
        let scrollValue = window.scrollY;
        let rootPosition = getElementPosition(this.innovationRoot.current);
        let rootYPosition = rootPosition.y;
        let windowWidth = window.innerWidth;
        let containerWidth = this.innovationSlider.current.offsetWidth;
        let windowHeight = window.innerHeight;
        let offsetValue = 500;
        let titleMovingWidth = windowWidth - (2*windowWidth*0.07) - this.innovationTitle.current.offsetWidth;
        let a = windowHeight-offsetValue;
        let b = scrollValue+windowHeight-(rootYPosition+offsetValue);
        let c = containerWidth-windowWidth+120;
        let d = (scrollValue + windowHeight) < (rootYPosition+offsetValue) ? 0 : (scrollValue > rootYPosition?c:(b/a)*c);
        let titleTranslateValue = (scrollValue + windowHeight) < (rootYPosition+offsetValue) ? 0 : (scrollValue > rootYPosition?titleMovingWidth:(b/a)*titleMovingWidth);
        //this.innovationSlider.current.style.transform = `translate(${-d}px,0)`;
        this.innovationSlider.current.style.left = `${-d}px`;
        this.innovationSlider.current.style.position = `relative`;
        this.innovationTitle.current.style.transform= `translate(${titleTranslateValue}px,0)`;
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("scroll", this.windowScroll)
        }
    }
    showMore=()=>{
        if((this.state.index+1)*3<this.props.innovationItems.length){
            this.setState({index:this.state.index+1,showCount:(this.state.index+1)*3});
        }else{
            this.setState({index:this.state.index+1,showCount:this.props.innovationItems.length,hasMore:false});
        }

    }
    render() {
        const {innovation,activeLang,innovationItems} = this.props;
        return (
            <div>
                <div className="innovation-root scrollanim" ref={this.innovationRoot}>
                    <div className="innovation-container">
                        <div className="innovation-block">
                            <h2 ref={this.innovationTitle}>{innovation.title}</h2>
                            <div className="slider-block">
                                <div className="innovation-slider-block">
                                    <div className="innovation-slider" ref={this.innovationSlider} >
                                        <div className="link-item">
                                            <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/innovation`}>
                                                <div className="line"></div>
                                                <span>{innovation.button}</span>
                                            </Link>
                                        </div>

                                        <div className="list-block-content" ref={this.innovationBlock}>
                                            {
                                                innovationItems && innovationItems.length > 0 && innovationItems.slice(0,this.isMobileInovOpen?this.state.showCount:innovationItems.length).map((item, key) =>
                                                    <InnovationImgItemComponent activeLang={activeLang} key={key} {...item}/>
                                                )
                                            }
                                            {
                                                this.state.hasMore && <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/innovation`} className="btn-item">
                                                    <div className="regular-btn light">
                                                        <span>Discover All Innovations</span>
                                                    </div>
                                                </Link>
                                            }

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    innovation: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].innovation : [],
    innovationItems: state.homeReducer.innovation?state.homeReducer.innovation.slice(0,5):[],
    activeLang: state.langReducer.activeLang
});
export default connect(mapStateToProps)(InnovationComponent);
