import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import LazyLoad from "react-lazyload";
import IMG from "../../../components/common/lazyComponent";
class KnowledgeImageComponent extends Component {

    state={
        imgBroken:false
    }

    checkImgSourceBroken = (event) => {
        event.target.src = `${ASSETS}/img/placeholderImage.jpg`
        this.setState({
            imgBroken:true
        })
     };
    render() {
 
        const {classname, image, name, imgPath} = this.props;
        return (
            <div className={`image-block ${classname ? classname : ''}`}>
                <div className="image-item">
                    <LazyLoad placeholder={<IMG/>}>
                    <picture>
                        {/*<source*/}
                        {/*        srcSet={`${ASSETS}${img1xWebp} 1x, ${ASSETS}${img2xWebp} 2x, ${ASSETS}${img3xWebp} 3x`}*/}
                        {/*        type="image/webp"/>*/}
                        {/*<source*/}
                        {/*        srcSet={`${ASSETS}${img1x} 1x, ${ASSETS}${img2x} 2x, ${ASSETS}${img3x} 3x`}*/}
                        {/*        type="image/jpeg"/>*/} {/*//TODO: akıbeti belirsizdir*/}

                        {
                            this.state.imgBroken ?        <img  src={`${ASSETS}/img/placeholderImage.jpg`}  alt='placeholder'/>
                            :
                            <img src={`${imgPath}${image}`} onError={(e) => this.checkImgSourceBroken(e)} alt={name}/>
                        }
                 
                      
                    </picture>
                    </LazyLoad>
                </div>
                <div className="inner-text-item">
                    <p>{name}</p>
                </div>
            </div>
        );
    }
}

export default KnowledgeImageComponent;
