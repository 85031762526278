export const HOME = "/:lang";
export const ARTICLE_DETAIL = "/:lang/article/:link";
export const ARTICLE_DETAIL_WITHOUT_PARAM = "/article/";
export const ARTICLE_LIST  = "/:lang/article-list/:tag_link?";
export const ARTICLE_LIST_WITHOUT_PARAM  = "/article-list";
export const CORPORATE = "/:lang/corporate/:subLink?";
export const CORPORATE_WITHOUT_LINK = "/corporate";
export const CONTACT = "/:lang/contact/:formName?";
export const CONTACT_WITHOUT_LINK = "/contact";
export const PRIVACY = "/:lang/privacy";
export const COOKIE = "/:lang/cookie";
export const SUSTAINBILITY = "/:lang/home/sustainbility";
export const LANG_TR = "tr";
export const LANG_EN = "en";
export const SLASH_ROUTE = "/";
export const JOB_APP_LINK = "job-application-form";
export const CONTACT_FORM_LINK = "contact-form";
export const PRODUCTIVITY= "/:lang/qr/verimlilik";
export const KVKK = "/:lang/qr/kvkk";

