import React, {Component} from 'react';

class VideoModalComponent extends Component {
    lbBlock = React.createRef()
    render() {
        let {setSelectedVideo,selectedVideo} = this.props;
        return (
            <div className="lb-root" onClick={(e)=>{
                if(this.lbBlock.current.contains(e.target)===false){
                    setSelectedVideo(false);
                }
            }}>
                <div className="lb-block">
                    <div className="lb-content-block video-lb-block" ref={this.lbBlock}>
                        <div className="close-btn" onClick={()=>{
                            setSelectedVideo(false)
                        }}>
                            <i className="gg-close"></i>
                        </div>
                        <div className="iframe-block">
                            <iframe src={selectedVideo.video_link}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default VideoModalComponent;
