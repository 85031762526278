import React, {Component} from 'react';
import BannerBigSlideItem from "../../../components/home/banner/bannerBigSlideItem";
import BannerSmallSliderItem from "../../../components/home/banner/bannerSmallSliderItem";
import BannerSliderTextItem from "../../../components/home/banner/bannerSliderTextItem";
import BannerArrowComponent from "../../../components/home/banner/bannerArrowComponent";
import BannerSliderCountComponent from "../../../components/home/banner/bannerSliderCountComponent";
import {connect} from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CircleComponent from "../../../components/home/banner/circleComponent";


class BannerSliderComponent extends Component {
    sliderItem = React.createRef();
    sliderSmallItem = React.createRef();
    sliderTextItem = React.createRef();
    thumbnailsArr;
    circleKey = 0;
    createFirstObj = false;
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            canLoadAll: false,
            oldIndex:-1
        };
        if (typeof window !== "undefined") {
            window.addEventListener("resize", this.resizeSlider, {passive: true});
        }
    }

    componentDidMount() {
        this.createFirstObj = true;
        this.forceUpdate();
        this.resizeSlider();
        if (typeof window !== "undefined" && (this.sliderItem.current && this.sliderSmallItem.current)) {
            this.sliderItem.current.addEventListener('touchstart', this.handleTouchStart, false);
            this.sliderSmallItem.current.addEventListener('touchstart', this.handleTouchStart, false);
            this.sliderTextItem.current.addEventListener('touchstart', this.handleTouchStart, false);
            this.sliderItem.current.addEventListener('touchmove', this.handleTouchMove, false);
            this.sliderSmallItem.current.addEventListener('touchmove', this.handleTouchMove, false);
            this.sliderTextItem.current.addEventListener('touchmove', this.handleTouchMove, false);
        }
        if(this.props.banner && this.props.banner.length>0){
            this.createSmallBannerItems();
        }
        this.interval = setInterval(this.autoBannerMovement, 10000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.banner.length !== this.props.banner.length){
            this.createSmallBannerItems();
        }
    }

    resizeSlider = () => {
        if ((this.sliderItem.current && this.sliderItem.current.style) && (this.sliderSmallItem.current && this.sliderSmallItem.current.style)) {
            this.changeTransitionDuration("0s")
            this.slideBanner();
            setTimeout(() => {
                if (this.sliderItem.current && this.sliderSmallItem.current) {
                    this.changeTransitionDuration(null)
                }
            })
        }
    };

    infiniteSlider = (index, direction) => {
        let newIndex = direction === -1 ? index - 1 : index + 1;
        this.setState({
            activeIndex: index,
            oldIndex: this.state.activeIndex,
            canLoadAll: true
        }, () => {
            this.changeTransitionDuration("0s")
            this.slideBanner();
            setTimeout(() => {
                this.changeTransitionDuration(null)
                this.setState({
                    activeIndex: newIndex,
                }, () => {
                    this.slideBanner();
                });
            });
        });
    };

    changeTransitionDuration = (duration) => {
        this.sliderItem.current.style.transitionDuration = duration;
        this.sliderSmallItem.current.style.transitionDuration = duration;
    }

    clearBannerInterval = () => {
        clearInterval(this.interval);
        this.interval = setInterval(this.autoBannerMovement, 10000);
    };

    autoBannerMovement = () => {
        this.changeDirection(1);
    };

    changeDirection = (direction) => {
        if (this.state.activeIndex === 0 && direction === -1) {
            this.infiniteSlider(this.props.banner.length, direction);
            return;
        }
        if (this.state.activeIndex === this.props.banner.length - 1 && direction === 1) {
            this.infiniteSlider(-1, direction);
            return;
        }
        this.setState({
            activeIndex: this.state.activeIndex + direction,
            oldIndex: this.state.activeIndex,
            canLoadAll: true
        }, () => {
            this.slideBanner(direction);
        });

    };

    slideBanner = () => {
        this.circleKey+=1;
        this.forceUpdate();
        this.sliderItem.current.style.transform = `translateX(${-(this.sliderItem.current.offsetWidth * (this.state.activeIndex + 1))}px)`
        this.sliderSmallItem.current.style.transform = `translateX(${-(this.sliderSmallItem.current.offsetWidth * ((this.state.activeIndex + 1)))}px)`
    };

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.resizeSlider);
            this.sliderItem.current.removeEventListener('touchstart', this.handleTouchStart, false);
            this.sliderSmallItem.current.removeEventListener('touchstart', this.handleTouchStart, false);
            this.sliderTextItem.current.removeEventListener('touchstart', this.handleTouchStart, false);
            this.sliderItem.current.removeEventListener('touchmove', this.handleTouchMove, false);
            this.sliderSmallItem.current.removeEventListener('touchmove', this.handleTouchMove, false);
            this.sliderTextItem.current.removeEventListener('touchmove', this.handleTouchMove, false);
        }
    };

    handleTouchStart = (evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
    };

    handleTouchMove = (evt) => {
        if (!this.xDown || !this.yDown) {
            return;
        }
        this.xUp = evt.touches[0].clientX;
        this.yUp = evt.touches[0].clientY;

        let xDiff = this.xDown - this.xUp;
        let yDiff = this.yDown - this.yUp;
        if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    this.changeDirection(1);
                } else {
                    this.changeDirection(-1);
                }
            }
            this.xDown = null;
            this.yDown = null;
        }
    };

    createSmallBannerItems = () => {
        const {banner} = this.props;
        if(!this.thumbnailsArr){
            let smallBanner = [...banner]
            smallBanner.shift();
            this.thumbnailsArr = [...smallBanner,banner[0]]
            this.forceUpdate();
        }

    }

    render() {
        const {activeIndex} = this.state;
        const {banner} = this.props;

        return (
            <div className="slide-root">
                <div className="slide-wrapper">
                    {/*<CountdownCircleTimer
                        isPlaying
                        key={this.circleKey}
                        duration={10}
                        strokeWidth={4}
                        size={96}
                        colors={[
                            ['#004777', 1]
                        ]}
                    >
                        {({ remainingTime }) => `${this.state.activeIndex+1}/${banner.length}`}
                    </CountdownCircleTimer>*/}
                    <CircleComponent circleKey={this.circleKey} text={`${this.state.activeIndex+1} / ${banner.length}`}/>
                    <div className="slide-container">
                        <div className="slide-image-container">
                            <div className="slide-image-block">
                                <div className="slide-image-item" ref={this.sliderItem}>
                                    {
                                        (this.createFirstObj && banner && banner.length > 0) &&
                                        <BannerBigSlideItem {...banner[banner.length - 1]}/>
                                    }
                                    {
                                        banner && banner.map((item, key) =>
                                            <BannerBigSlideItem key={key} {...item}/>
                                        )
                                    }
                                    {
                                        banner && banner.length > 0 &&
                                        <BannerBigSlideItem{...banner[0]} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="slide-small-image-container">
                            <div className="slide-small-image-block" onClick={() => {
                                this.clearBannerInterval();
                                this.changeDirection(1);
                            }}>
                                <div className="slide-small-image-item" ref={this.sliderSmallItem}>
                                    {
                                        (this.createFirstObj && this.thumbnailsArr && this.thumbnailsArr.length > 0) &&
                                        <BannerSmallSliderItem {...banner[0]}/>
                                    }
                                    {
                                        this.thumbnailsArr && this.thumbnailsArr.map((item, key) =>
                                            <BannerSmallSliderItem key={key} {...item}/>
                                        )
                                    }
                                    {
                                        this.thumbnailsArr && this.thumbnailsArr.length > 0 &&
                                        <BannerSmallSliderItem {...banner[1]} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="slide-text-block " ref={this.sliderTextItem}>
                            {
                                banner && banner.length > 0 &&
                                <BannerSliderTextItem activeIndex={-1} oldIndex={this.state.oldIndex}
                                                      index={activeIndex === -1 ? -1 : null} {...banner[banner.length - 1]}/>
                            }
                            {
                                banner && banner.map((item, key) =>
                                    <BannerSliderTextItem activeIndex={activeIndex} key={key}
                                                          index={key} {...item} oldIndex={this.state.oldIndex}/>
                                )
                            }
                            {
                                banner && banner.length > 0 &&
                                <BannerSliderTextItem activeIndex={banner.length} oldIndex={this.state.oldIndex}
                                                      index={activeIndex === banner.length ? banner.length : null} {...banner[0]} />
                            }
                        </div>
                        {/*<BannerSliderCountComponent activeIndex={activeIndex} mainImage={banner}/>*/}
                        <BannerArrowComponent clearBannerInterval={this.clearBannerInterval} changeDirection={this.changeDirection}/>
                    </div>
                    <div className="bottom-bg-item">
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    banner: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].banner.items : [],
});

export default connect(mapStateToProps)(BannerSliderComponent);