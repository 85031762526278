import React,{Component} from 'react';
import {checkElementInViewPort} from "../../helpers";

class ScrollControlContainer extends Component{
    contentSliderRoot = React.createRef();
    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", this.scrollingPage)
        }
    }

    scrollingPage = () => {
        checkElementInViewPort(this.contentSliderRoot.current)
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("scroll", this.scrollingPage)
        }
    }
    render() {
        return <div ref={this.contentSliderRoot} className={this.props.class}>
            {
                this.props.children
            }
        </div>
    }
}

export default ScrollControlContainer;