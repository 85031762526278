import React, {Component} from 'react';
import {connect} from "react-redux";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE} from "../../constants/routePaths";

class HeaderTagsComponent extends Component{
    state = {openMore:false};
    render() {
        const {activeLang,tags,history} = this.props;
        return(
            <nav className="menu-block">
                <ul>
                    {
                        tags&& tags.length > 0 && tags.slice(0,7).map((item, key) =>
                            <li key={key} onClick={()=>{
                                history.push(`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`)
                            }}>{item.tag_name}</li>
                        )
                    }
                    {
                        (tags && tags.length>7) &&
                        <li className={`open-down-list ${this.state.openMore===true?"active":""} `} onClick={()=>{this.setState({openMore:!this.state.openMore})}}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul className="open-down-items">
                                {
                                    tags.slice(7).map((item, key) =>
                                        <li key={key} onClick={()=>{
                                            history.push(`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`)
                                        }}>{item.tag_name}</li>
                                    )
                                }
                            </ul>

                        </li>
                    }

                    <li onClick={this.props.changeLang}>{activeLang === "tr" ?"EN" : "TR"}</li>
                </ul>
            </nav>
        )
    }
};


const mapStateToProps = (state) => ({
    tags: state.articleListTagsReducer.articleListTags,
    activeLang: state.langReducer.activeLang
});

export default connect(mapStateToProps)(HeaderTagsComponent);