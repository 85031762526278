import React, {Component} from 'react';
import ContentArrowComponent from "../home/content/contentArrowComponent";
import {getElementWidth} from "../../helpers";
import RangeItemComponent from "./rangeItemComponent";

class SliderComponent extends Component {
    sliderContainer = React.createRef();
    contentSliderContainer = React.createRef();
    state = {
        activeIndex: 0
    }
    itemWidth=0;
    showingCount = 2;
    componentDidMount() {
        this.itemWidth = getElementWidth(this.sliderContainer.current.children[0]);
        this.showingCount = Math.floor((this.contentSliderContainer.current.offsetWidth / this.itemWidth)+0.2)
        this.forceUpdate();
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.setItemWidth);
        }
        if (typeof window !== "undefined") {
             this.contentSliderContainer.current.addEventListener('touchstart',this.handleTouchStart, false);
        }

        if (typeof window !== "undefined") {

            this.contentSliderContainer.current.addEventListener('touchmove',this.handleTouchMove, false);

        }
    }
    handleTouchStart=(evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
    };
    handleTouchMove=(evt) => {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }
        this.xUp = evt.touches[0].clientX;
        this.yUp = evt.touches[0].clientY;


        let xDiff = this.xDown - this.xUp;
        let yDiff = this.yDown - this.yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
            evt.preventDefault();
            if ( xDiff > 0 ) {
                this.changeActiveIndex(1);
            } else {
                this.changeActiveIndex(-1);
            }
        }
        this.xDown = null;
        this.yDown = null;
    };

    setItemWidth = () => {
        this.itemWidth = getElementWidth(this.sliderContainer.current.children[0]);
        this.showingCount = Math.floor((this.contentSliderContainer.current.offsetWidth / this.itemWidth)+0.2)
    }

    changeActiveIndex = (direction) => {
        if((this.state.activeIndex===0 && direction === -1) || (Math.ceil(this.props.children.length/this.showingCount)-1===this.state.activeIndex && direction===1)){
            return;
        }
        this.setState({
            activeIndex: this.state.activeIndex + direction
        })
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize', this.setItemWidth);
        }
    }

    render() {
        let containerWidth = this.props.children.length*this.itemWidth;
        let translateValue = this.state.activeIndex*(this.showingCount*this.itemWidth);
        let windowInnerWidth = this.showingCount*this.itemWidth;
        return (
            <div>
                <div className="content-slider" ref={this.contentSliderContainer}>
                    <div className="slider" style={{transform:`translate(${(containerWidth - windowInnerWidth)>translateValue || translateValue===0 ?-translateValue:-(containerWidth - windowInnerWidth)}px,0)`}} ref={this.sliderContainer}>
                        {this.props.children}
                    </div>
                </div>
                <div className="nav-block light-color">
                    {
                        this.props.children.length/this.showingCount > 1 &&
                        <RangeItemComponent pageSize={Math.ceil(this.props.children.length/this.showingCount)} activeIndex={this.state.activeIndex}/>
                    }

                    {
                        this.props.children.length/this.showingCount > 1 &&
                        <ContentArrowComponent isPreviousPassive={0===this.state.activeIndex} isNextPassive={Math.ceil(this.props.children.length/this.showingCount)-1===this.state.activeIndex}  changeActiveIndex={this.changeActiveIndex}/>
                    }
                </div>
            </div>
        )
    }
};

export default SliderComponent;
