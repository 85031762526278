import React, {Component} from 'react';
import InputFormComponent from "./inputFormComponent";
import {Field, initialize, reduxForm, reset} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import TextAreaFormFieldComponent from "./textAreaFormFieldComponent";
import RenderSelectComponent from "./renderSelectComponent";
import RecaptchaFieldComponent from "./recaptchaFieldComponent";
import {saveCVAction, submitFormAction} from "../../actions/index";
import {getElementPosition, maxLength} from "../../helpers/index";
import RadioFieldComponent from "./radioFieldComponent";
import FormRadioComponent from "./formRadioComponent";
import {ASSETS} from "../../constants/paths";

const required = value => value ? undefined : 'Required';
const maxLength100 = maxLength(100);
const validate = (values, props) => {
    let errors = {};
    let hasErrors = false;
    console.log("values----",values);

    if (!/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = " ";
        hasErrors = true;
    }


    return hasErrors && errors;
};

class ContactFormComponent extends Component {
    formContainer = React.createRef();
    fileName = null;
    recaptchaConfirmed = true;
    conditionChecked = true;
    conditionErr = false;
    addFileErr = false;
    sucsessModalOpen = false;
    value;
    state = {
        formType: "Contact"
    };

    changeFormType = (val) => {
        this.props.dispatch(initialize("AddContactForm",{}));
        this.conditionChecked = true;
        this.conditionErr = false;
        this.recaptchaConfirmed = true;
        this.addFileErr = false;
        window.grecaptcha.reset();
        this.sucsessModalOpen = false;
        this.forceUpdate();
    }
    submitForm = (values) => {
        values.formType = this.state.formType;
        // this.props.dispatch(submitFormAction(values));
        const response = window.grecaptcha.getResponse();
        console.log("VALUES---",values,response);
        this.loading = true;
        if (response.length == 0) {
            this.recaptchaConfirmed = false;
            this.forceUpdate();
        } else {
            //this.props.dispatch(submitFormAction(values));

            if (this.state.formType === "Contact") {
                if (this.conditionChecked === true) {
                    console.log("112121212---");
                    this.props.dispatch(submitFormAction(values, (res) => {
                        if (res === true) {
                            console.log("res---",res);
                            this.props.dispatch(reset("AddContactForm"));
                            this.conditionChecked = true;
                            this.conditionErr = false;
                            this.recaptchaConfirmed = true;
                            window.grecaptcha.reset();
                            this.sucsessModalOpen = true;
                            this.loading = false;
                            this.value = 5;
                            this.countToHideModal();
                            this.forceUpdate();
                        }
                    }));
                } else {
                    this.conditionErr = true;
                    this.forceUpdate();
                }

            } else {
                if (!this.file) {
                    //this.file = new File([], "no-file.pdf", {"type": "application/pdf"});
                    if (this.fileName === null) {
                        this.addFileErr = true;
                        this.forceUpdate();
                    }

                } else {
                    if (this.file && this.conditionChecked === true) {
                        this.addFileErr = true;
                        this.loading = true;
                        this.forceUpdate();
                        this.props.dispatch(saveCVAction(this.file, response)).then((res) => {
                                if (res.data.success) {
                                    values["cv_path"] = res.data.success;
                                    this.props.dispatch(submitFormAction(values, (res) => {
                                        if (res === true) {
                                            this.props.dispatch(reset("AddContactForm"));
                                            this.conditionChecked = true;
                                            this.file = null;
                                            this.fileName = null;
                                            this.conditionErr = false;
                                            this.recaptchaConfirmed = true;
                                            this.sucsessModalOpen = true;
                                            this.value = 5;
                                            window.grecaptcha.reset();
                                            this.addFileErr = false;
                                            this.loading = false;
                                            this.countToHideModal();
                                            this.forceUpdate();
                                        }
                                    }));
                                    this.fileError = null;
                                } else {
                                    if (res.data.status === 1) {
                                        this.recatchValidation = null;
                                        this.showErrorRecatcha = true;
                                    }
                                    this.fileError = "File Error";
                                    this.file = null;
                                }
                                this.loading = false;
                                this.forceUpdate();
                            }
                        ).catch((e)=>{console.log("error----",e);});
                    } else {
                        this.conditionErr = this.conditionChecked !== true ? true : null;
                        //this.fileError = this.file ? null : "File Error";
                        //this.formError = "";
                        this.forceUpdate();
                    }
                }


            }


        }
    };
    timer;
    countToHideModal = () => {
        clearTimeout(this.timer);
        this.value--;
        if (this.value > 0) {
            this.timer = setTimeout(this.countToHideModal, 5000);
        } else {
            this.sucsessModalOpen = false;
            this.setState({isButtonDisabled: false});
        }
        this.forceUpdate();
    };
    changeFile = (e) => {
        let typeArr = ["application/pdf", "application/msword"];
        if (e.target.files[0] && e.target.files[0].size < 5048576 && (typeArr.indexOf(e.target.files[0].type) > -1)) {
            this.file = e.target.files[0];
            this.fileName = e.target.files[0].name;
            this.fileError = null;
            this.fileTypeError = null;
            this.fileSizeError = null;
        } else if (typeArr.indexOf(e.target.files[0].type) === -1) {
            this.file = null;
            this.fileName = null;
            this.fileSizeError = null;
            this.fileTypeError = true;
        } else if (e.target.files[0].size >= 5048576) {
            this.file = null;
            this.fileName = null;
            this.fileTypeError = null;
            this.fileSizeError = true;
        } else {
            this.file = null;
            this.fileName = null;
            this.fileTypeError = null;
            this.fileSizeError = null;
            this.fileError = "File Error";
        }
        this.forceUpdate();
    };
    cancelUploadCV = () => {
        this.file = null;
        this.fileName = null;
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.match.params.formName && this.props.contactData) {
            this.scrollToFormControl();
        }
    }

    componentDidUpdate(prevProps) {
        if (((!prevProps.contactData && this.props.contactData) || (prevProps.location.pathname != this.props.location.pathname)) && this.props.match.params.formName) {
            this.scrollToFormControl();
        }
    }

    scrollToFormControl = () => {
        if (window != undefined) {
            window.scrollTo({left: 0, top: getElementPosition(this.formContainer.current).y - 100, behavior: 'smooth'});
        }
        this.setState({
            formType: (this.props.match.params.formName === "contact-form" ? "Contact" : "Job")
        })
    }

    render() {
        const {handleSubmit, contactData,submitFailed,requestCatalogData} = this.props;
        console.log("SUBMİT FAİLED------",submitFailed);
        return (
            <div ref={this.formContainer}>
                {
                    contactData && contactData.forms &&
                    <form onSubmit={handleSubmit(this.submitForm)} className="form-root">
                        <div className="form-container">
                            <div className="form-title-item">
                                <h2 className={`${this.state.formType === "Contact" ? "active" : ""}`} onClick={() => {
                                    this.setState({formType: "Contact"});
                                    this.changeFormType("Contact");
                                }}>{contactData.forms.contact_title}</h2>
                                <h2 className={`${this.state.formType === "Job" ? "active" : ""}`} onClick={() => {
                                    this.setState({formType: "Job"});
                                    this.changeFormType("Job");
                                }}>{contactData.forms.job_title}</h2>
                            </div>
                            <div className="input-container">
                                <div className="input-block">
                                    {
                                        this.state.formType === "Contact" &&
                                        <Field
                                            name="name_c"
                                            label={contactData.forms.name + "*"}
                                            type="text"
                                            placeholder={contactData.forms.name_placeholder}
                                            component={InputFormComponent}
                                            validate={[required,maxLength100]}
                                            //validate={[required]}
                                        />
                                    }
                                    {
                                        this.state.formType === "Job" &&
                                        <Field
                                            name="name_j"
                                            label={contactData.forms.name + "*"}
                                            type="text"
                                            placeholder={contactData.forms.name_placeholder}
                                            component={InputFormComponent}
                                            validate={[required,maxLength100]}
                                            //validate={[required]}
                                        />
                                    }

                                    {
                                        this.state.formType === "Contact" &&
                                        <Field
                                            name="email"
                                            label={contactData.forms.email + "*"}
                                            type="text"
                                            placeholder={contactData.forms.email_placeholder}
                                            component={InputFormComponent}
                                            validate={[required]}
                                            //validate={[required]}
                                        />
                                    }
                                    {
                                        this.state.formType === "Job" &&
                                        <Field
                                            name="position"
                                            label={contactData.forms.position}
                                            positions={contactData.forms.position_list}
                                            component={RenderSelectComponent}
                                            // validate={[required]}
                                        />
                                    }

                                    <Field
                                        name="description"
                                        label={contactData.forms.description}
                                        type="text"
                                        // placeholder="Açıklama"
                                        component={TextAreaFormFieldComponent}
                                        //validate={[required]}
                                    />
                                    {/*<Field*/}
                                        {/*name={"catalog_type"}*/}
                                        {/*options={[*/}
                                            {/*...requestCatalogData.catalog_type*/}
                                        {/*]}*/}
                                        {/*component={formRadioComponent}*/}
                                    {/*/>*/}

                                </div>
                                <Field
                                    name="condition"
                                    label={contactData.forms.approve}
                                    component={FormRadioComponent}
                                    // validate={[required]}
                                />
                                {
                                    this.state.formType === "Job" && !this.fileName ?
                                        <div className={`add-file ${(submitFailed || this.addFileErr) && this.fileName === null?"error":""}`}>
                                            <input type="file"
                                                   onChange={this.changeFile}
                                                   accept="application/pdf,application/msword"/>
                                            <span className="btn-3">{contactData.forms.addCv}</span>
                                            {
                                                this.state.formType === "Job" && (submitFailed || this.addFileErr) && (this.fileName === null) &&
                                                <div className="oap-msg-item">
                                                    <span>{contactData.forms.addCVError}</span>
                                                </div>
                                            }
                                        </div>
                                        :
                                        this.state.formType === "Job" ?
                                            <div className="upl-btn-cancel">
                                                <div className="fileName">{this.fileName}</div>
                                                <div className="line"></div>
                                                <div className="cancel" onClick={() => {
                                                    this.cancelUploadCV()
                                                }}>{contactData.forms.cancel}

                                                </div>
                                            </div> : null
                                }
                                <div className="input-bottom-block">

                                    <Field
                                        name="recaptcha"
                                        component={RecaptchaFieldComponent}
                                        validate={required}
                                    />

                                    <div className="input-item">
                                        <input type="submit" value={contactData.forms.button}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
                {(this.loading === true) &&
                    <div className="msgBlock">
                        <div className="contentItem">
                            <img src={`${ASSETS}/img/loading.gif`}
                                 alt="Loading..."/>
                        </div>
                    </div>
                }
                {
                    this.sucsessModalOpen === true && this.state.formType === "Contact" &&
                    <div className="oap-msg-item success">
                        <span>{contactData && contactData.successContact} <i className="icon-success" /> </span>
                    </div>
                }
                {
                    this.sucsessModalOpen === true && this.state.formType === "Job" &&
                    <div className="oap-msg-item success">
                        <span>{contactData && contactData.successJob} <i className="icon-success" /> </span>
                    </div>
                }
                {
                    this.recaptchaConfirmed === false &&
                    <div className="oap-msg-item">
                        <span>{contactData.forms.recapcha_required}</span>
                    </div>
                }
                {
                    this.fileSizeError === true &&
                    <div className="oap-msg-item">
                        <span>{contactData.forms.over_limit}</span>
                    </div>
                }

                {
                    this.fileTypeError === true &&
                    <div className="oap-msg-item">
                        <span>{contactData.forms.disallowed_file_type}</span>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = reduxForm({
    form: "AddContactForm",
    validate
})(ContactFormComponent);

export default withRouter(connect(state => (
    {
        formErrors: state.form.AddContactForm ? state.form.AddContactForm.syncErrors : {},
        contactData: state.contactReducer[state.langReducer.activeLang] ? state.contactReducer[state.langReducer.activeLang].contactData : null,
        requestCatalogData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].request_catalog:null
    }
))(mapStateToProps));

//export default ContactFormComponent;
