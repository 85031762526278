import {GET_HOME_DATA,GET_HOME_ARTICLES} from "../constants/actionTypes";


const initialState = {letClick: false};

const homeReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_HOME_DATA:
            return {
                ...state,
                success: {...state.success, ...action.payload},
                ...action.payload
            };
        case GET_HOME_ARTICLES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default homeReducer;