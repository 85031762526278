import React, {Component} from 'react';

class BannerSliderCountComponent extends Component {
    render() {
        const {activeIndex, mainImage} = this.props;
        return (
            <div className="slide-count-block">
                <div className="slide-count-item">
                    <div className="slide-count">
                        <span> {activeIndex + 1} / {mainImage.length}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannerSliderCountComponent;