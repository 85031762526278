import axios from 'axios';
import {API_URL, DATA_URL} from "../constants/paths";

export const getHomeData = () => {
    return axios.get(`${DATA_URL}/home.json`);
};

export const getLayoutData = () => {
    return axios.get(`${DATA_URL}/layout.json`);
};

export const getArticleListData = (params) => {
    return axios.post(`${API_URL}article/getArticleList`,"params="+JSON.stringify(params));
};

export const getArticleTagListData = (params) => {
    return axios.get(`${API_URL}article/getTagList`);
};

export const getCorporateData = () => {
    return axios.get(`${DATA_URL}/corporate.json`);
};

export const getArticleDetail = (params) => {
    return axios.post(`${API_URL}article/getArticleDetail`,"params="+JSON.stringify(params));
};

export const getContactData = () => {
    return axios.get(`${DATA_URL}/contact.json`);
}

export const submitForm = (params) => {
    return axios.post(`${API_URL}contact/addForm`,"params="+JSON.stringify(params));
};
export const saveCV = (CV,recatchValidation) => {
    let data = new FormData();
    data.append("file",CV);
    return axios.post(`${API_URL}contact/saveCV`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

export const submitRequestCatalogForm = (params)=>{
    return axios.post(`${API_URL}contact/createRequest`,"params="+JSON.stringify(params));
}

export const getPrivacy = () => {
    return axios.get(`${DATA_URL}/privacy.json`)
}

export const getCookie = () => {
    return axios.get(`${DATA_URL}/cookie.json`)
}
export const getProductivity = () => {
    return axios.get(`${DATA_URL}/productivity.json`)
}
export const getKVKK= () => {
    return axios.get(`${DATA_URL}/kvkk.json`)
}
export const homeArticles = (params)=>{
    return axios.post(`${API_URL}home/homeArticles`,"params="+JSON.stringify(params));
}