import React, {Component} from 'react';
import HoverImageComponent from "../../common/hoverImageComponent";
import {addCursorConClass, removeCursorConClass} from "../../../helpers";

class BannerArrowComponent extends Component {
    /*onEnter = () => {
        addCursorConClass("hide-active");
    }
    onLeave = () => {
        removeCursorConClass("hide-active")
    }

    componentWillUnmount() {
        removeCursorConClass("hide-active")
    }*/
    render() {
        const {changeDirection, clearBannerInterval} = this.props;
        return (
            <div className="slide-arrow-block">
                <div className="arrow-item" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} onClick={() => {
                    clearBannerInterval();
                    changeDirection(-1);
                }}>
                    <div className="arrow-border"></div>
                    <HoverImageComponent img={"/img/back-1x.png"} img2x={"/img/back-2x.png"} />
                    <div className="arrow">
                        <i className="icon-banner-arrow"/>
                    </div>
                </div>
                <div className="arrow-item right" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave} onClick={() => {
                    clearBannerInterval();
                    changeDirection(1);
                }}>
                    <div className="arrow-border"></div>
                    <HoverImageComponent img={"/img/next-1x.png"} img2x={"/img/next-2x.png"} />
                    <div className="arrow">
                        <i className="icon-banner-arrow"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannerArrowComponent;
