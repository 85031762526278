import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import ReturnButtonComponent from "../../components/common/returnButtonComponent";
import PageService from "../../pageServices/pageService";
import {matchPath} from "react-router-dom";
import {ARTICLE_DETAIL, CORPORATE} from "../../constants/routePaths";
import ArticleInfoContainer from "../../containers/articleDetail/articleInfoContainer";
import ArticleShortDescContainer from "../../containers/articleDetail/articleShortDescContainer";
import ArticlePictureContainer from "../../containers/articleDetail/articlePictureContainer";
import {connect} from "react-redux";
import ArticleContentContainer from "../../containers/articleDetail/articleContentContainer";
import MultipleContentContainer from "../../containers/home/content/multipleContentContainer";
import SuggestComponent from "../../components/articleDetail/suggestComponent";
import {setDocumentTitle} from "../../helpers/index";
import NotFoundPage from "../notFoundPage";

class ArticleDetail extends Component {
    constructor(props) {
        super(props);
        this.getData();
    }

    componentDidMount() {
            setDocumentTitle(this.props.title);
    }

    getData = () => {
        let urlParams = matchPath(this.props.location.pathname, {
            path: ARTICLE_DETAIL,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch,urlParams.params);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.getData();
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props){
            if(nextProps.title){
                setDocumentTitle(nextProps.title);
            }
        }

    }
    render() {
        const {items,isNotFound,description} = this.props;
        return (
            <div>
                {
                    !isNotFound ?
                        <div className="article-detail-root">
                            <div className="article-detail-container">
                                <article className="article-root">
                                    <div className="article-container ">
                                        <div className="safe-area">
                                            <ReturnButtonComponent activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>
                                            <ArticleInfoContainer activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>

                                        </div>
                                        <ArticleShortDescContainer activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>
                                        <div className={"safe-area article-content-block "+(description?"":"no-desc")}>
                                            <ArticlePictureContainer activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>
                                            <ArticleContentContainer activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>
                                        </div>

                                    </div>
                                </article>
                                <div className="content-slider-root">
                                    <div className="content-slider-container">
                                        <SuggestComponent activeLang={this.props.match.params.lang} articleLink={this.props.match.params.link}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <NotFoundPage {...this.props}/>
                }

            </div>
        );
    }
}
const mapStateToProps = (state,ownProps) => ({
    title:state.articleDetailReducer[ownProps.match.params.link]?state.articleDetailReducer[ownProps.match.params.link].title:null,
    isNotFound:state.articleDetailReducer[ownProps.match.params.link]===false,
    description: state.articleDetailReducer[ownProps.match.params.link] ? state.articleDetailReducer[ownProps.match.params.link].description : null
})
export default connect(mapStateToProps)(ArticleDetail);