import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import InputFormComponent from "../formFields/inputFormComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import RadioFieldComponent from "../formFields/radioFieldComponent";
import {changeHoverImageAction, submitRequestCatalogFormAction} from "../../actions/index";
import {addCursorConClass, maxLength, removeCursorConClass} from "../../helpers/index";
import FormRadioComponent from "../formFields/formRadioComponent";
import {ASSETS} from "../../constants/paths";
import HoverImageComponent from "../common/hoverImageComponent";
import ReviewComponent from "../home/review/reviewComponent";
import CursorComponent from "../common/cursorComponent";

const validate = (values, props) => {
    let errors = {};
    let hasErrors = false;

    if (!values.name || values.name.trim() === "") {
        errors.name = " ";
        hasErrors = true;
    }
    if (!values.surname || values.surname.trim() === "") {
        errors.surname = " ";
        hasErrors = true;
    }
    if (!values.company || values.company.trim() === "") {
        errors.company = " ";
        hasErrors = true;
    }
    if (!values.actual_catalogue && !values.company_profile) {
        errors.actual_catalogue = " ";
        errors.company_profile = " ";
        hasErrors = true;
    }
    if (!values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = " ";
        hasErrors = true;
    }

    console.log(errors, hasErrors, props);

    return hasErrors && errors;
};
const maxLength50 = maxLength(50);
const maxLength200 = maxLength(200);

class RequestCatalog extends Component {
    fileName = null;
    recaptchaConfirmed = true;
    state = {
        isSuccess: null
    }

    submitForm = (values) => {
        console.log(values.recaptcha);
        //const response = window.grecaptcha.getResponse();
        //console.log(response);
        if (!values.recaptcha) {
            this.recaptchaConfirmed = false;
            this.forceUpdate();
        } else {
            values.catalog_type = (values.actual_catalogue ? "Actual Catalogue," : "") + (values.company_profile ? " Company Profile," : "");
            values.catalog_type = values.catalog_type.slice(0, -1);
            values.recaptcha = values.recaptcha;
            this.props.dispatch(submitRequestCatalogFormAction(values, (res) => {
                this.props.changeRequestValue(false)
                if (res) {
                    console.log("başarılı");
                    this.setState({isSuccess: true});
                } else {
                    console.log("başarısız");
                    this.setState({isSuccess: false});
                }
            }));
        }
    };
    onEnter = () => {
        this.props.dispatch(changeHoverImageAction("view"));
        addCursorConClass("show-view");
    }
    onLeave = () => {
        removeCursorConClass("show-view")
    }

    render() {
        let {changeRequestValue, handleSubmit, requestCatalogData, link} = this.props;
        return (
            <div className="lb-root">
                {/*<CursorComponent/>*/}
                <div className="lb-block">
                    {
                        this.state.isSuccess === null &&
                        <div className="lb-content-container">
                          {/*  <div className="lb-content-block catalog-lb-top-block ">
                                <div className="close-btn" onClick={() => {
                                    changeRequestValue(false)
                                }}>
                                    <i className="gg-close"></i>
                                </div>
                                <div className="head-block">
                                    <h2>{requestCatalogData.title}</h2>
                                    <p>{requestCatalogData.desc}</p>
                                </div>

                                <div className="cat-down-block">
                                    <a href={`${ASSETS}/files/company-profile/02_08_2021_Unlu_Tekstil_Company_Profile.pdf`}
                                       target="_blank" className="cat-down-item company-cat-block arrow-item">
                                        <div className="catalog-item" onMouseEnter={this.onEnter}
                                             onMouseLeave={this.onLeave}>
                                            <div className="arrow-border"></div>
                                            <HoverImageComponent img={"/img/view-1x.png"} img2x={"/img/view_2x.png"}/>
                                            <div className="arrow"><i className="icon-crossed-arrow"></i></div>
                                            <div className="cat-down-top">
                                                <div className="text-item">
                                                    <p>{requestCatalogData.catalog_type[1].name}</p>
                                                    <span>2021</span>
                                                </div>
                                                <div className="img-item">
                                                    <picture>
                                                        <source
                                                            srcSet={`${ASSETS}/img/company-profile.webp`}
                                                            type="image/webp"/>
                                                        <source
                                                            srcSet={`${ASSETS}/img/company-profile.jpg`}
                                                            type="image/jpeg"/>

                                                        <img src={`${ASSETS}/img/company-profile.jpg`}
                                                             alt="actual catolog"/>
                                                    </picture>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="down-btn">
                                            <span>{requestCatalogData.download}</span>
                                        </div>
                                    </a>
                                    <a href={`${ASSETS}/files/sustainability-profile/02_08_2021_Unlu_Tekstil_Sustainability.pdf`}
                                       target="_blank" className="cat-down-item arrow-item">
                                        <div className="arrow-border"></div>
                                        <HoverImageComponent img={"/img/view-1x.png"} img2x={"/img/view-2x.png"}/>
                                        <div className="arrow"><i className="icon-crossed-arrow"></i></div>
                                        <div className="catalog-item" onMouseEnter={this.onEnter}
                                             onMouseLeave={this.onLeave}>
                                            <div className="cat-down-top">
                                                <div className="text-item">
                                                    <p>{requestCatalogData.catalog_type[0].name}</p>
                                                    <span>2021</span>
                                                </div>
                                                <div className="img-item">
                                                    <picture>
                                                        <source
                                                            srcSet={`${ASSETS}/img/sustainability-profile-img.webp`}
                                                            type="image/webp"/>
                                                        <source
                                                            srcSet={`${ASSETS}/img/sustainability-profile-img.jpg`}
                                                            type="image/jpeg"/>
                                                        <img src={`${ASSETS}/img/sustainability-profile-img.jpg`}
                                                             alt="sustainability-cat"/>
                                                    </picture>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="down-btn">
                                            <span>{requestCatalogData.download}</span>
                                        </div>
                                    </a>
                                </div>


                            </div>*/}
                            <div className="lb-content-block catalog-lb-bottom-block">
                                <div className="close-btn" onClick={() => {
                                    changeRequestValue(false)
                                }}>
                                    <i className="gg-close"></i>
                                </div>
                                <div className="head-block">
                                    <h2>{requestCatalogData.request_more}</h2>
                                    {/*<p>{requestCatalogData.desc}</p>*/}
                                </div>
                                <form onSubmit={handleSubmit(this.submitForm)}>
                                    <div className="tab-block">
                                        {/*<Field
                                    name={"catalog_type"}
                                    options={[
                                        ...requestCatalogData.catalog_type
                                    ]}
                                    component={RadioFieldComponent}
                                />*/}

                                    </div>
                                    <div className="form-block">
                                        <div className="input-block">
                                            <Field
                                                name="name"
                                                label={requestCatalogData.name.label}
                                                type="text"
                                                placeholder={requestCatalogData.name.placeholder}
                                                component={InputFormComponent}
                                                validate={[maxLength50]}
                                                //validate={[required]}
                                            />
                                            <Field
                                                name="surname"
                                                label={requestCatalogData.surname.label}
                                                type="text"
                                                placeholder={requestCatalogData.surname.placeholder}
                                                component={InputFormComponent}
                                                validate={[maxLength50]}
                                                //validate={[required]}
                                            />
                                        </div>
                                        <div className="input-block bottom">
                                            <Field
                                                name="company"
                                                label={requestCatalogData.company.label}
                                                type="text"
                                                placeholder={requestCatalogData.company.placeholder}
                                                component={InputFormComponent}
                                                validate={[maxLength200]}
                                                //validate={[required]}
                                            />
                                            <Field
                                                name="email"
                                                label={requestCatalogData.email.label}
                                                type="text"
                                                placeholder={requestCatalogData.email.placeholder}
                                                component={InputFormComponent}
                                                //validate={[required]}
                                            />
                                            <Field
                                                name="needs"
                                                label={requestCatalogData.need_desc.label}
                                                type="text"
                                                placeholder={requestCatalogData.need_desc.placeholder}
                                                component={InputFormComponent}
                                                //validate={[required]}
                                            />
                                        </div>

                                        <div className="bottom-block">
                                            <Field
                                                name="recaptcha"
                                                component={RecaptchaFieldComponent}
                                            />
                                            <div className="input-item">
                                                <input type="submit" value={requestCatalogData.submit}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>


                    }

                </div>
            </div>
        )
    }
}

const _RequestCatalog = reduxForm({
    // a unique name for the form
    form: 'requestCatalogForm',
    validate,
    initialValues: {
        catalog_type: 'Actual Catalogue'
    }
})(RequestCatalog)
const mapStateToProps = state => ({
    activeLang: state.langReducer.activeLang,
    requestCatalogData: state.layoutReducer[state.langReducer.activeLang] ? state.layoutReducer[state.langReducer.activeLang].request_catalog : null
})
export default connect(mapStateToProps)(_RequestCatalog)
