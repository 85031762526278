import async from "async";
import * as WebService from '../services/webService';
import * as actionTypes from "../constants/actionTypes";

const receiveArticleDetail = (data) => ({
    type: actionTypes.GET_ARTICLE_DETAIL,
    payload: data
});

export const getArticleDetailDataInit = (dispatch,params) => {
    return new Promise((resolve,failure)=>{
        async.series([
            (cb) => {
                WebService.getArticleDetail(params)
                    .then((res) => {
                        if (res.data.success) {
                            cb(null,res.data.success);
                        }else{
                            cb(null,false);
                        }
                    })
                    .catch((err) => {
                    console.log("ERR----",err);
                        cb(null,false);
                    })
            }
        ],(err,result)=>{
            dispatch(receiveArticleDetail(result[0]?result[0]:{[params.articleLink]:false}));
            resolve(result);
        });
    });


};