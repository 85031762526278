import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {ARTICLE_LIST_WITHOUT_PARAM, SLASH_ROUTE, SUSTAINBILITY} from "../../../constants/routePaths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class MenuListComponent extends Component {
    render() {
        let {tags,activeLang,footerData} = this.props;
        return (
            <div className="right-block">
                {
                    footerData &&
                    <ul className="footer-list-item">
                        <li><NavLink to={`${SLASH_ROUTE}${activeLang}/${footerData.company_link}`}>{footerData.company}</NavLink></li>
                        {
                            footerData.companyMenus.map((menuItem,key)=>
                                <li key={key}><NavLink key={key} to={`${SLASH_ROUTE}${activeLang}/${footerData.company_link}/${menuItem.link}`}>{menuItem.name}</NavLink></li>
                            )
                        }
                    </ul>
                }
                <ul className="footer-list-item is-menu-list">
                    {
                        tags && tags.slice(0,Math.ceil(tags.length/2)).map((item,key)=>
                            <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`}>{item.tag_name}</NavLink></li>
                        )
                    }
                </ul>
                <ul className="footer-list-item is-menu-list">
                    {
                        tags && tags.slice(Math.ceil(tags.length/2)).map((item,key)=>
                            <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`}>{item.tag_name}</NavLink></li>
                        )
                    }
                </ul>
                {
                    footerData &&
                    <ul className="footer-list-item">
                        <li><NavLink to={`${SLASH_ROUTE}${activeLang}/${footerData.contact_link}`}>{footerData.contact}</NavLink></li>
                        {
                            footerData.contactMenus.map((menuItem,key)=>
                                <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}/${footerData.contact_link}${menuItem.link?"/"+menuItem.link:``}`}>{menuItem.name}</NavLink></li>
                            )
                        }
                    </ul>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    tags: state.articleListTagsReducer.articleListTags,
    activeLang: state.langReducer.activeLang,
    footerData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].footer:null
});

export default connect(mapStateToProps)(MenuListComponent);
