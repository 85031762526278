import {GET_KVKK_DATA, GET_PRODUCTIVITY_DATA} from "../constants/actionTypes";

const initialState = {};

const productivityReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_PRODUCTIVITY_DATA:
            return {
                ...state,
                ...action.payload
            };
       
        case GET_KVKK_DATA:
            return {
                ...state,
                ...action.payload
            };
            
            default:
                return state;
    }
};

export default productivityReducer;