import React, {Component} from 'react';
import BannerSliderComponent from "../../../containers/home/banner/bannerSliderComponent";
import BannerLeftSideComponent from "./bannerLeftSideComponent";
import ScrollDownComponent from "../../common/scrollDownComponent";

class BannerComponent extends Component {

    render() {
        return (
            <div className="banner-root">
                <div className="banner-container">
                    <div className="banner-block">
                        <BannerLeftSideComponent/>
                        <div className="right-block">
                            <BannerSliderComponent/>
                        </div>
                        <div className="bottom-bg-item">
                            <ScrollDownComponent/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannerComponent;
