import {GET_PRIVACY_DATA} from "../constants/actionTypes";

const initialState = {};

const privacyReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_PRIVACY_DATA:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default privacyReducer;