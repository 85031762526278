import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import PageService from "../../pageServices/pageService";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
import {setDocumentTitle} from "../../helpers/index";
class CookiePolicyPage extends Component {
    componentDidMount() {
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch);
        if(this.props.cookieData && this.props.cookieData.metas && this.props.cookieData.metas.title){
            setDocumentTitle(this.props.cookieData.metas.title);
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.cookieData && nextProps.cookieData.metas && (nextProps.cookieData!== this.props.cookieData)){
            if(nextProps.cookieData.metas && nextProps.cookieData.metas.title){
                setDocumentTitle(nextProps.cookieData.metas.title);
            }
        }

    }
    render() {
        let {cookieData} = this.props;
        return (
            <div>
                <div className="privacy-root">
                    <div className="sub-banner-root safe-area">
                        <div className="sub-banner-block">
                            {
                                cookieData &&
                                <figure className="img-item">
                                    {/*<LazyLoad placeholder={<IMG/>}>*/}
                                    <picture>
                                        <source
                                            srcSet={`${ASSETS}${cookieData.img1X} 1x, ${ASSETS}${cookieData.img2X} 2x, ${ASSETS}${cookieData.img3X} 3x`}
                                            type="image/jpeg"/>
                                        <source
                                            srcSet={`${ASSETS}${cookieData.img1X.substring(0, cookieData.img1X.lastIndexOf("."))}.webp 1x, ${ASSETS}${cookieData.img2X.substring(0, cookieData.img2X.lastIndexOf("."))}.webp 2x, ${ASSETS}${cookieData.img3X.substring(0, cookieData.img3X.lastIndexOf("."))}.webp 3x`}
                                            type="image/webp"/>
                                        <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg`}
                                             alt="article-list"/>
                                    </picture>
                                    {/*</LazyLoad>*/}
                                </figure>
                            }

                        </div>
                    </div>
                    {
                        cookieData &&
                        <div className="content-root safe-area">
                            <div className="content-block">
                                <h1>{cookieData.title}</h1>
                                {cookieData.descriptions.map((descItem,key)=>
                                    <p key={key}>{descItem}</p>
                                ) }
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cookieData: state.cookieReducer[state.langReducer.activeLang]
})

export default connect(mapStateToProps)(CookiePolicyPage);
