import React, {Component} from 'react';
import {RECAPTCHA_SITE_KEY} from "../../constants/paths";
import ReCAPTCHA from "react-google-recaptcha";
import {connect} from "react-redux";
import {change} from "redux-form";
class RecaptchaFieldComponent extends Component {
    recaptchaItem = React.createRef();

    onChange = (token) => {
        this.props.dispatch(change(this.props.meta.form,"recaptcha",token))
    }

    render() {
        const {meta: {error, submitFailed}} = this.props;
        return (
                <div className={`recaptcha-wrapper ${error && submitFailed ? "error" : ""}`}>
                    <ReCAPTCHA
                        ref={this.recaptchaItem}
                        sitekey={`${RECAPTCHA_SITE_KEY}`}
                        onChange={this.onChange}
                        hl={"tr"}
                    />
                </div>
        );
    }
}



export default connect()(RecaptchaFieldComponent);