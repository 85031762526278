import * as ROUTES from '../constants/routePaths';
import {
    getCorporateDataInit,
    getHomeDataInit
} from './homeServices';
import {
    getArticleListDataInit
} from './articleListServices';
import {getArticleDetailDataInit} from "./articleDetailServices";
import {LANG_EN, LANG_TR} from "../constants/routePaths";
import {getAllPageService} from "./allPageService";
import {getContactDataInit} from "./contactService";
import {getPrivacyDataInit} from "./privacyService";
import {getCookieDataInit} from "./cookieService";
import {getProductivityDataInit } from './productivityService';
import { getKvkkDataInit } from './kvkkService';

export default class PageService {
    currentPage = ROUTES.HOME;

    constructor(url){
        this.currentPage = url;
    }

    serviceData = (dispatch, urlParams, resolve) => {
        let params;
        switch (this.currentPage) {
            case ROUTES.HOME.replace(":lang",LANG_TR):
            case ROUTES.HOME.replace(":lang",LANG_EN):
            case ROUTES.HOME.replace(":lang",LANG_TR)+"/":
            case ROUTES.HOME.replace(":lang",LANG_EN)+"/":
                params = {lang:urlParams.lang};
                getHomeDataInit(dispatch,params)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case `${ROUTES.SLASH_ROUTE}${urlParams&& urlParams.lang?urlParams.lang:'tr'}${ROUTES.CONTACT_WITHOUT_LINK}${urlParams && urlParams.formName?"/"+urlParams.formName:""}`:
                getContactDataInit(dispatch)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case ROUTES.PRIVACY.replace(":lang",LANG_TR):
            case ROUTES.PRIVACY.replace(":lang",LANG_EN):
                getPrivacyDataInit(dispatch)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case ROUTES.COOKIE.replace(":lang",LANG_TR):
            case ROUTES.COOKIE.replace(":lang",LANG_EN):
                getCookieDataInit(dispatch)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
                case ROUTES.KVKK.replace(":lang",LANG_TR):
                case ROUTES.KVKK.replace(":lang",LANG_EN):
                    getKvkkDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                        });
                    break;
                case ROUTES.PRODUCTIVITY.replace(":lang",LANG_TR):
                    getProductivityDataInit(dispatch)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case `${ROUTES.SLASH_ROUTE}${urlParams && urlParams.lang? urlParams.lang:'tr'}${ROUTES.CORPORATE_WITHOUT_LINK}${urlParams && urlParams.subLink?"/"+urlParams.subLink:""}`:
                getCorporateDataInit(dispatch)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case `${ROUTES.SLASH_ROUTE}${urlParams && urlParams.lang? urlParams.lang:'tr'}${ROUTES.ARTICLE_LIST_WITHOUT_PARAM}${urlParams && urlParams.tag_link?"/"+urlParams.tag_link:""}`:
                params = {"index":0,lang:urlParams.lang,...urlParams};
                params.tag = urlParams.tag_link?urlParams.tag_link:undefined;
                getArticleListDataInit(dispatch,params)
                    .then((res)=>{
                        resolve(res);
                    });
                break;
            case (urlParams ? `${ROUTES.SLASH_ROUTE}${urlParams && urlParams.lang? urlParams.lang:'tr'}${ROUTES.ARTICLE_DETAIL_WITHOUT_PARAM}${urlParams && urlParams.link?urlParams.link:""}`:null):
                params = {articleLink:urlParams.link,lang:urlParams.lang}
                getArticleDetailDataInit(dispatch,params).then((res)=>{
                    resolve(res);
                });
                break;
            case '':
                resolve(false);
                break;
            default:
                resolve(false);
                break;
        }
    }

    getData = (dispatch,urlParams) =>{
        return new Promise((resolve,reject)=>{
            getAllPageService(dispatch)
                .then((res) => {
                    this.serviceData(dispatch, urlParams, resolve);
                }).catch((err) => {
                this.serviceData(dispatch, urlParams, resolve);
            })
        });
    }
}
