export const GET_HOME_DATA = "GET_HOME_DATA";
export const GET_HOME_ARTICLES = "GET_HOME_ARTICLES";
export const GET_ARTICLE_LIST_DATA = "GET_ARTICLE_LIST_DATA";
export const RESET_ARTICLE_FILTER = "RESET_ARTICLE_FILTER";
export const GET_ARTICLE_TAGS_DATA = "GET_ARTICLE_TAGS_DATA";
export const GET_LAYOUT_DATA = "GET_LAYOUT_DATA";
export const GET_ARTICLE_DETAIL = "GET_ARTICLE_DETAIL";
export const GET_CORPORATE_DATA = "GET_CORPORATE_DATA";
export const GET_CONTACT_DATA = "GET_CONTACT_DATA";
export const GET_PRIVACY_DATA = "GET_PRIVACY_DATA";
export const GET_COOKIE_DATA = "GET_COOKIE_DATA";
export const GET_ARTICLE_LIST_FETCH_DATA = "GET_ARTICLE_LIST_FETCH_DATA";
export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_HOVER_IMG = "CHANGE_HOVER_IMG";
export const GET_PRODUCTIVITY_DATA = "GET_PRODUCTIVITY_DATA";
export const GET_KVKK_DATA = "GET_KVKK_DATA"
