import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
class MissionVisionContainer extends Component{
    render() {
        let {mission_vision,safety_policy,quality_policy} = this.props;
        return(
            <div className="company-profile-block" id={"contents-4"}>
                {
                    mission_vision &&
                    <div className="img-block">
                        <figure className="img-item">
                            <LazyLoad placeholder={<IMG/>}>
                            <picture>
                                <source
                                    srcSet={`${ASSETS}${mission_vision.img1x} 1x, ${ASSETS}${mission_vision.img2x} 2x, ${ASSETS}${mission_vision.img3x} 3x`}
                                    type="image/jpeg"/>
                                <source
                                    srcSet={`${ASSETS}${mission_vision.img1x.substring(0, mission_vision.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${mission_vision.img2x.substring(0, mission_vision.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${mission_vision.img3x.substring(0, mission_vision.img3x.lastIndexOf("."))}.webp 3x`}
                                    type="image/webp"/>
                                <img src={`${ASSETS}${mission_vision.img1x} 1x`}
                                     alt="article-list"/>
                            </picture>
                            </LazyLoad>
                        </figure>
                    </div>
                }
                {
                    mission_vision &&
                    <div className="description-item">
                        <h2>{mission_vision.missionTitle}</h2>
                        {
                            mission_vision.missionDescriptions.map((item,key)=>
                                <p key={key}>{item}</p>
                            )
                        }
                        <h2>{mission_vision.visionTitle}</h2>
                        {
                            mission_vision.visionDescriptions.map((item,key)=>
                                <p key={key}>{item}</p>
                            )
                        }
                    </div>
                }
                {
                    safety_policy&&
                    <div className="description-item">
                        <h2>{safety_policy.title}</h2>
                        {
                            safety_policy.descriptions.map((item,key)=>
                                <p key={key}>{item}</p>
                            )
                        }
                        <ol className="info-list-item">
                            {
                                safety_policy.items.map((item,i)=>
                                    <li key={i}>{item}</li>
                                )
                            }
                        </ol>
                        <h2>{quality_policy.title}</h2>
                        {
                            quality_policy.descriptions.map((item,key)=>
                                <span className="line-by-line-item" key={key}>{item}</span>
                            )
                        }
                    </div>
                }
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    mission_vision: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].mission_vision:null,
    safety_policy: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].information_safety_policy:null,
    quality_policy: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].quality_policy:null
});


export default connect(mapStateToProps)(MissionVisionContainer);
