import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class BannerSliderTextItem extends Component {
    render() {
        const {index, head, link, text, activeIndex,oldIndex} = this.props;
        return (
            <div className={`slide-text-item ${activeIndex === index ? "active" : ""} ${oldIndex === index ? "hide-active" : ""}`}>
                <div className="head-item">
                    <p>{head}</p>
                </div>
                <div className="desc-item">
                    <p>{text}</p>
                </div>
                <div className="link-item">
                    <NavLink to={link}>
                        <div className="line"></div>
                        <span>Explore</span>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default BannerSliderTextItem;
