import React, {Component} from 'react';
import {matchPath} from "react-router-dom";
import {HOME} from "../constants/routePaths";
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import {setDocumentTitle} from "../helpers/index";

class NotFoundPage extends Component{
    constructor(props) {
        super(props);
        let urlParams = matchPath(this.props.location.pathname, {
            path: HOME,
            exact: false,
            strict: false
        });
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(this.props.dispatch,urlParams?urlParams.params:null);
        setDocumentTitle("404 | Ünlü Tekstil");
    }

    render() {
        return(
            <div className="not-found-root">
                <div className="content-block">
                    <div className="text-block">
                        <h1>404</h1>
                        <p>Aradığınız sayfa bulunamadı</p>
                    </div>

                </div>

            </div>
        )
    }
}
export default connect()(NotFoundPage);