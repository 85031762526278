import React, {Component} from 'react';
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import IMG from "../../components/common/lazyComponent";
import LazyLoad from "react-lazyload";
class CorporateQualityTextContainer extends Component{
    render() {
        let {qualityData} = this.props;
        return(
            <section className="main-content-block">
                {
                    qualityData &&
                    <figure className="img-item">
                        {/*<LazyLoad placeholder={<IMG/>}>*/}
                        <picture>
                            <source
                                srcSet={`${ASSETS}${qualityData.img1x} 1x, ${ASSETS}${qualityData.img2x} 2x, ${ASSETS}${qualityData.img3x} 3x`}
                                type="image/jpeg"/>
                            <source
                                srcSet={`${ASSETS}${qualityData.img1x.substring(0, qualityData.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${qualityData.img2x.substring(0, qualityData.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${qualityData.img3x.substring(0, qualityData.img3x.lastIndexOf("."))}.webp 3x`}
                                type="image/webp"/>
                            <img src={`${ASSETS}${qualityData.img1x}`}
                                 alt="article-list"/>
                        </picture>
                        {/*</LazyLoad>*/}
                    </figure>

                }
                {
                    qualityData &&
                    <div className="description-item">
                        {
                            qualityData&&
                                <p>{qualityData.sub_text}</p>

                        }
                        {
                            qualityData.content&&
                            <div className="content-item">
                                <figure className="img-item">
                                    <picture>
                                        <source
                                            media="(max-width: 420px)"
                                            srcSet={`${ASSETS}${qualityData.content.img1x} 1x, ${ASSETS}${qualityData.content.img2x} 2x, ${ASSETS}${qualityData.content.img3x} 3x`}
                                            type="image/jpeg"/>
                                        <source
                                            media="(max-width: 420px)"
                                            srcSet={`${ASSETS}${qualityData.content.img1x.substring(0, qualityData.content.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${qualityData.content.img2x.substring(0, qualityData.content.img2x.lastIndexOf("."))}.webp 2x, ${ASSETS}${qualityData.content.img3x.substring(0, qualityData.content.img3x.lastIndexOf("."))}.webp 3x`}
                                            type="image/webp"/>
                                        <source
                                            media="(min-width: 421px)"
                                            srcSet={`${ASSETS}${qualityData.content.img1x} 1x, ${ASSETS}${qualityData.content.img1x} 2x`}
                                            type="image/jpeg"/>
                                        <source
                                            media="(min-width: 421px)"
                                            srcSet={`${ASSETS}${qualityData.content.img1x.substring(0, qualityData.content.img1x.lastIndexOf("."))}.webp 1x, ${ASSETS}${qualityData.content.img2x.substring(0, qualityData.content.img2x.lastIndexOf("."))}.webp 2x`}
                                            type="image/webp"/>
                                        <img src={`${ASSETS}${qualityData.content.img1x}`}
                                             alt="article-list"/>
                                    </picture>
                                </figure>
                                <div className="text-item">
                                    {
                                        qualityData.content.texts.map((textItem,key)=>
                                            <p key={key}>{textItem}</p>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }

            </section>
        )
    }
};

const mapStateToProps = (state) => ({
    qualityData: state.corporateReducer.pageData?state.corporateReducer.pageData[state.langReducer.activeLang].quality:null
});

export default connect(mapStateToProps)(CorporateQualityTextContainer);
