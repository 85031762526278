import {CHANGE_HOVER_IMG, GET_LAYOUT_DATA} from "../constants/actionTypes";

const initialState = {hoverImg:"play"};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LAYOUT_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case CHANGE_HOVER_IMG:
            return {
                ...state,
                hoverImg: action.payload,
            };
        default:
            return state;
    }
};

export default layoutReducer;