import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";

class OurExportPolicyContainer extends Component{
    render() {
        return(
            <div className="company-profile-block">
                <div className="img-block">
                    <figure className="img-item">
                        <picture>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.jpg 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.jpg 3x`}
                                type="image/jpeg"/>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.webp 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.webp 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.webp 3x`}
                                type="image/webp"/>
                            <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x`}
                                 alt="article-list"/>
                        </picture>
                    </figure>
                </div>
                <div className="description-item">
                    <h2>Our Export Policy</h2>
                    <p>Thanks to our fully <b>fledged export department</b> and Authorized
                        Obligatory Certification we re able to export efficiently all over the
                        world. All kind of transportation and export/customs procedures are handled
                        by our company.
                    </p>
                    <p>Ünlü Tekstil is a textile manufacturer endorsed by world famous brands which
                        enjoy high quality production services of this manufacturing giant. Thanks
                        to its years of experience Ünlü Tekstil has been serving the versatile needs
                        of its customers with the help of specialized departments created over many
                        years.
                    </p>
                    <p>Integrated manufacturing structure means that Ünlü Tekstil can deliver a full
                        service - i.e. from design to quality assurance of all items - to the global
                        brands helping them build their reputation. We will also help you to
                        establish contact with Turkish and international fabric producers and guide
                        you through sale processes as well as other formal stages.</p>
                </div>
            </div>
        )
    }
}

export default OurExportPolicyContainer;
