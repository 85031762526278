import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";

class HoverImageComponent extends Component {
    render() {
        const {img, img2x,className} = this.props;
        return (
            <div className={"arrow-image "+(className?className:"")}>
                <picture>
                    <source
                        srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img2x} 2x`}
                        type="image/png"/>
                    <img src={`${ASSETS}${img}`} alt=""/>
                </picture>
            </div>
        );
    }
}

export default HoverImageComponent;
