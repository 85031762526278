import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {COOKIE, SLASH_ROUTE} from "../../constants/routePaths";


class CookiePolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isClicked: typeof window === "undefined" || localStorage.getItem("isSeen") == 'true'
        }
    }
    closeModal = () => {
        localStorage.setItem("isSeen",'true');
        this.setState({
            isClicked: true
        })
    }

    render() {
        let {cookieData,activeLang,cookie_link} =this.props
        return (

            !this.state.isClicked ?
                <div className="cookies-root">
                    {
                        cookieData &&
                        <div className="cookies-block">
                            <Link to={`${SLASH_ROUTE}${activeLang}/${cookieData.cookie_link}`} rel="noreferrer" title="Cookie Policy">
                                <p dangerouslySetInnerHTML={{__html:cookieData.text}}></p>
                            </Link>

                            <span onClick={this.closeModal} className="btn-1">{cookieData.btn_text}</span>
                        </div>
                    }

                </div>
                :
                null
        )
    }
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
    cookieData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].cookie:null,
    footerData: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].footer:null
});

export default connect(mapStateToProps)(CookiePolicy);
