import {act} from "react-dom/test-utils";
import {GET_ARTICLE_LIST_DATA, GET_ARTICLE_LIST_FETCH_DATA, RESET_ARTICLE_FILTER} from "../constants/actionTypes";


const initialState = {};

const articleListReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_ARTICLE_LIST_DATA:
            return {
                ...state,
                articleList: {...action.payload},
            };
        case GET_ARTICLE_LIST_FETCH_DATA:
            if (action.isFirst) {
                return {
                    ...state,
                    filterObj: action.filterObj,
                    articleList: {
                        [action.tag]: {
                            contentArticle: {
                                ...state.articleList[action.tag].contentArticle,
                                result: [...action.payload.contentArticle.result]
                            }
                        }
                    },
                };
            } else {
                return {
                    ...state,
                    articleList: {
                        [action.tag]: {
                            contentArticle: {
                                ...state.articleList[action.tag].contentArticle,
                                result: [...state.articleList[action.tag].contentArticle.result, ...action.payload.contentArticle.result]
                            }
                        }
                    },
                };
            }
        case RESET_ARTICLE_FILTER:
            return {
                ...state,
                filterObj:{}

            };
        default:

            return state;
    }
};

export default articleListReducer;