import {GET_ARTICLE_DETAIL} from "../constants/actionTypes";

const initialState = {};

const articleDetailReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_ARTICLE_DETAIL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default articleDetailReducer;