import async from "async";
import * as WebService from "../services/webService";
import * as actionTypes from "../constants/actionTypes";

const productivityData = (data) => ({
    type: actionTypes.GET_PRODUCTIVITY_DATA,
    payload: data
});

export const getProductivityDataInit = (dispatch) => {
    return new Promise((resolve,failure)=>{
        async.series([
            (cb) => {
                WebService.getProductivity()
                    .then((res) => {
                        if (res.data) {
                            cb(null,res.data);
                        }else{
                            cb(null, {});
                        }
                    })
                    .catch((err) => {
                        cb(null, {});
                    })
            }
        ],(err,result)=>{
            dispatch(productivityData(result[0]));
            resolve(result);
        });
    });
};

