import React, {Component} from 'react';
import {addCursorConClass, removeCursorConClass} from "../../../helpers";

class ContentArrowComponent extends Component {
   /* onEnter = () => {
        addCursorConClass("hide-active");
    }
    onLeave = () => {
        removeCursorConClass("hide-active")
    }

    componentWillUnmount() {
        removeCursorConClass("hide-active")
    }*/
    render() {
        const {changeActiveIndex,activeIndex, isPreviousPassive, isNextPassive} = this.props;
        return (
            <div className="slide-arrow-block">
                <div className={"arrow-item "+(isPreviousPassive?"passive":"")} onMouseEnter={()=>{
                    if(!isPreviousPassive){
                        // this.onEnter()
                    }
                }} onMouseLeave={this.onLeave} onClick={()=>{
                    changeActiveIndex(-1)
                }}>

                    <div className="arrow">
                        {/*<HoverListComponent textValue={"Back"}/>*/}
                        <i className="icon-banner-arrow"/>
                    </div>
                </div>
                <div className={"arrow-item right "+(isNextPassive?"passive":"")} onMouseEnter={()=>{
                    if(!isNextPassive){
                        // this.onEnter()
                    }
                }} onMouseLeave={this.onLeave} onClick={()=>{
                    changeActiveIndex(1)
                }}>

                    <div className="arrow">
                        {/*<HoverListComponent textValue={"Next"}/>*/}
                        <i className="icon-banner-arrow"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentArrowComponent;
