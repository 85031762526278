import React, {Component} from 'react';


class FormRadioComponent extends Component {
    render() {
        let {input,label,meta:{touched,error}} = this.props;
        return (
            <div className={(touched && error) ? "checkboxItem error" : "checkboxItem"}>
                <div className="radio-block" onClick={(e)=>{
                    input.onChange(input.value!=true)
                }}>
                    <div className="radio-item">
                        <span></span>
                        <input className="" checked={input.value==true}  onChange={(e) => {}} type="checkbox" name="confirm"/>
                        <label className="text" dangerouslySetInnerHTML={{__html: label}}></label>
                    </div>
                </div>

            </div>
        );
    }
}
export default FormRadioComponent


