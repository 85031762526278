import React, {Component} from 'react';
import HoverImageComponent from "../../common/hoverImageComponent";


class ContentPlayButtonComponent extends Component {
    render() {
        const {changeDirection} = this.props;
        return (
            <div className="slide-play-block">
                <div className="arrow-item" onClick={() => {

                }}>
                    <div className="arrow-border"></div>
                    <HoverImageComponent img={"/img/play-1x.png"} img2x={"/img/play-2x.png"}/>
                    <div className="arrow">
                        <i className="icon-play"/>
                    </div>
                </div>

            </div>
        );
    }
}

export default ContentPlayButtonComponent;
