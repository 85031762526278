import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";

class TrainingContainer extends Component{
    render() {
        return(
            <div className="company-profile-block">
                <div className="img-block">
                    <figure className="img-item">
                        <picture>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.jpg 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.jpg 3x`}
                                type="image/jpeg"/>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.webp 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.webp 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.webp 3x`}
                                type="image/webp"/>
                            <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x`}
                                 alt="article-list"/>
                        </picture>
                    </figure>
                </div>
                <div className="description-item">
                    <h2>Training Programs</h2>
                    <p>The training programs we schedule guide our employees in many paths, namely
                        their job description, work flow, targets, related reports, and when and if
                        employees lost their sense of direction we are there to assist them. All
                        these trainings are offered in line with the company’s quality management
                        system and when employees are recruited, and during their career in the
                        company.</p>
                </div>
            </div>
        )
    }
};

export default TrainingContainer;