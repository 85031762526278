import React, {Component} from "react";
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";
import {ARTICLE_DETAIL_WITHOUT_PARAM, SLASH_ROUTE} from "../../constants/routePaths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";

class ArticleListItemComponent extends Component{

    render(){
        let {activeLang,min} = this.props;
        const art = this.props.article;
        const _date = new Date(art.created_date);
        return(
            <Link to={`${SLASH_ROUTE}${activeLang}${ARTICLE_DETAIL_WITHOUT_PARAM}${art.content_link}`} className="article-block">
                <div className="image-item">
                    <LazyLoad placeholder={<IMG/>}>
                    <picture>
                        <source
                            media="(min-width: 421px)"
                            srcSet={`${ASSETS}${art.list_image} 1x, ${art.list_image_2X ? `${ASSETS}${art.list_image_2X} 2x`:""} `}
                                type="image/jpeg"/>
                        <source
                            media="(min-width: 421px)"
                            srcSet={`${ASSETS}${art.list_image.substring(0, art.list_image.lastIndexOf(".")) + ".webp"} 1x,${art.list_image_2X ? `${ASSETS}${art.list_image_2X.substring(0, art.list_image_2X.lastIndexOf(".")) + ".webp"} 2x`:""} `}
                                type="image/webp"/>
                        <source
                            media="(max-width: 420px)"
                            srcSet={`${ASSETS}${art.mobile_list_image.substring(0, art.mobile_list_image.lastIndexOf(".")) + ".webp"} 1x,${art.mobile_list_image_2X ? `${ASSETS}${art.mobile_list_image_2X.substring(0, art.mobile_list_image_2X.lastIndexOf(".")) + ".webp"} 2x, `:"" } ${art.mobil_list_image_3X ? `${ASSETS}${art.mobil_list_image_3X.substring(0, art.mobil_list_image_3X.lastIndexOf(".")) + ".webp"} 3x `:"" } `}
                            type="image/webp"/>
                        <source
                            media="(max-width: 420px)"
                            srcSet={`${ASSETS}${art.mobile_list_image} 1x,${art.mobile_list_image_2X ? `${ASSETS}${art.mobile_list_image_2X} 2x, `:""} ${art.mobil_list_image_3X ? `${ASSETS}${art.mobil_list_image_3X} 3x` :""} `}
                            type="image/jpeg"/>

                        <img src={`${ASSETS}${art.list_image}`}
                                alt="article-list"/>
                    </picture>
                    </LazyLoad>
                </div>
                <div className="text-item">
                    <span>{_date.getFullYear()} • {art.duration} {min}</span>
                    <p>{art.title}</p>
                </div>
            </Link>
        )
    }

}
export default ArticleListItemComponent;
