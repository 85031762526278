import React, {Component} from 'react'
import {ASSETS} from "../../constants/paths";

class CompanyHRContainer extends Component{
    render() {
        return(
            <div className="company-profile-block">
                <div className="img-block">
                    <figure className="img-item">
                        <picture>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.jpg 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.jpg 3x`}
                                type="image/jpeg"/>
                            <source
                                srcSet={`${ASSETS}/img/corporate/corporate-main-img@1x.webp 1x, ${ASSETS}/img/corporate/corporate-main-img@2x.webp 2x, ${ASSETS}/img/corporate/corporate-main-img@3x.webp 3x`}
                                type="image/webp"/>
                            <img src={`${ASSETS}/img/corporate/corporate-main-img@1x.jpg 1x`}
                                 alt="article-list"/>
                        </picture>
                    </figure>
                </div>
                <div className="description-item">
                    <h2>Human Resources Policy</h2>
                    <p>In our eyes, our employees are the most important and valuable resource of
                        our company. We are a company that is aware of the importance of internal
                        training and productive working conditions, so with this in mind we make
                        sure that various communication channels are created for our employees and
                        they are offered training from the first day they joined the company.</p>
                </div>
            </div>
        )
    }
};

export default CompanyHRContainer;