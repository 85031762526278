import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/main.css';
import {createStore, applyMiddleware} from "redux";
import reducer from "./reducers";
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {Provider} from 'react-redux'
import {BrowserRouter} from "react-router-dom";
import App from "./App";


const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}

let store = createStore(reducer, window.initialStoreData ? window.initialStoreData : {}, applyMiddleware(...middleware));

ReactDOM.hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);