import React, {Component} from 'react';
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {articleListFetchDataAction} from "../../actions"

class SearchComponent extends Component {

    searchText= React.createRef();
    sortBy= React.createRef();
    sortByName= React.createRef("Newest");
    state={
        _sortName:'Newest',
        _sortKey:'created_date'
    }
    componentDidMount() {
        if(typeof window !== "undefined") {
            window.addEventListener('resize',this.resizingWindow);
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener('resize',this.resizingWindow);
        }
    }
    // resizingWindow = (e) => {
    //    if (window.innerWidth < 641) {
    //        document.body.classList.add('no-scroll')
    //    }
    //     this.handleOnClick(e);
    //
    // }
    getFetchData=()=>{

        this.props.dispatch(articleListFetchDataAction({"activeLang":this.props.activeLang,"index":0,"tag":this.props.tag_link,"searchTerm":this.searchText.current.value,"sortBy":this.state._sortKey},true));
    }
    handlerOnKeyDown=(e)=>{
        //if(e.keyCode ===13){
            //e.preventDefault();
            this.getFetchData();
        //}
    }

    handleOnClick=(e)=>{
        e.preventDefault();
        if(typeof window !== 'undefined'){
            this.sortBy.current.classList.toggle("active")
            if(e.currentTarget.className.indexOf('active') === -1 ){
                document.body.classList.remove('no-scroll')
            }else if (e.currentTarget.className.indexOf('active') !== -1 && window.innerWidth < 640){
                document.body.classList.add('no-scroll')
            }
        }

    }
    handleOnClickItem=(e,itemKey,itemVal)=>{
        e.preventDefault();
        this.setState({
            _sortName:itemVal,
            _sortKey:itemKey
        },()=>{
            this.getFetchData();
        })
    }
    render() {
        console.log(this.state._sortName)
        return (
            <form>
                <div className="search-root">
                    <div className="search-container">
                        <div className="input-item">
                            <input type="text" placeholder="Search" ref={this.searchText}  onKeyUp={this.handlerOnKeyDown} />
                        </div>
                        <div className="select-container" >
                            <div className="select-block" ref={this.sortBy} onClick={this.handleOnClick}>
                                <div className="select-item">
                                    <p>Sort by: <span>{this.state._sortName}</span></p>
                                    <i className="gg-options"></i>
                                    <i className="icon-arrow-down"></i>
                                </div>
                                <div className="option-block" >
                                    <div className="option-item">
                                        <div className="title-item">
                                            <h3>Filter requests by</h3>
                                        </div>
                                        <ul>
                                            <li className={this.state._sortKey === "created_date"?"active":""} onClick={(e)=>{this.handleOnClickItem(e,"created_date","Newest")}} >Newest</li>
                                            <li className={this.state._sortKey === "read_time"?"active":""} onClick={(e)=>{this.handleOnClickItem(e,"read_time","Read Time")}}>Read Time</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>

        );
    }
}

export default connect()(SearchComponent);
