import React, {Component} from 'react';

class InputFieldComponent extends Component {

    render() {
        const {type,label,placeholder,input,meta:{error,submitFailed}}=this.props;
        return (

            <div className={`oap-input-item ${error && submitFailed ? "error" : ""}`} >
                {
                    label &&
                    <label>{label}</label>
                }
                <input autoComplete="off" type={type} placeholder={placeholder} {...input}/>
            </div>
        );
    }
}


export default InputFieldComponent;
