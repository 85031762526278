import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {ASSETS} from "../../../constants/paths";
import {connect} from "react-redux";

class FooterLeftSideComponent extends Component {
    render() {
        let {addressList, activeLang} = this.props;
        return (
            <div className="left-block">
                <div className="logo-item">
                    <NavLink to="/home">
                        <picture>
                            <source
                                    srcSet={`${ASSETS}/img/footer-logo/unlu-footer-logo@1x.webp 1x, ${ASSETS}/img/footer-logo/unlu-footer-logo@2x.webp 2x, ${ASSETS}/img/footer-logo/unlu-footer-logo@3x.webp 3x`}
                                    type="image/webp"/>
                            <source
                                    srcSet={`${ASSETS}/img/footer-logo/unlu-footer-logo@1x.png 1x, ${ASSETS}/img/footer-logo/unlu-footer-logo@2x.png 2x, ${ASSETS}/img/footer-logo/unlu-footer-logo@3x.png 3x`}
                                    type="image/jpeg"/>
                            <img src={`${ASSETS}/img/footer-logo/unlu-footer-logo@1x.png`} alt="footer-logo"/>
                        </picture>
                    </NavLink>
                </div>
                <div className="text-item">
                    {
                        addressList && addressList.map((item,key)=>
                            <p key={key}>{item}</p>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
    addressList: state.layoutReducer[state.langReducer.activeLang]?state.layoutReducer[state.langReducer.activeLang].footer.address:null
});

export default connect(mapStateToProps)(FooterLeftSideComponent);