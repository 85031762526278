import React, {Component} from 'react';
import {ASSETS} from "../../../constants/paths";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {SLASH_ROUTE} from "../../../constants/routePaths";
import IMG from "../../common/lazyComponent";
import LazyLoad from "react-lazyload";
class BannerLeftSideComponent extends Component {
    render() {
        const {banner,activeLang}=this.props;
        return (
            <div className="left-block">
                <div className="logo-item">
                    {/*<LazyLoad placeholder={<IMG/>}>*/}
                    <picture>
                        <source srcSet={`${ASSETS}/img/banner-logo/banner-logo@1x.webp 1x, ${ASSETS}/img/banner-logo/banner-logo@2x.webp 2x, ${ASSETS}/img/banner-logo/banner-logo@3x.webp 3x`}
                                type="image/webp"/>
                        <source srcSet={`${ASSETS}/img/banner-logo/banner-logo@1x.png 1x, ${ASSETS}/img/banner-logo/banner-logo@2x.png 2x, ${ASSETS}/img/banner-logo/banner-logo@3x.png 3x`}
                                type="image/png"/>
                        <img src={`${ASSETS}/img/banner-logo/banner-logo@1x.png`} alt="banner-logo"/>
                    </picture>
                    {/*</LazyLoad>*/}
                </div>
                <div className="desc-item">
                    <p dangerouslySetInnerHTML={{__html:banner.desc}}></p>
                </div>
                <div className="link-item">
                    <Link to={`${SLASH_ROUTE}${activeLang}${banner.link}`}>
                        <div className="line"></div>
                        <span>{banner.btn}</span>
                    </Link>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    banner: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].banner : {},
    activeLang: state.langReducer.activeLang
});
export default connect(mapStateToProps)(BannerLeftSideComponent);